import { Tldraw, Editor, createTLStore, TLStore, TLStoreOptions, StoreSnapshot, TLRecord, defaultShapeTools, defaultShapeUtils } from '@tldraw/tldraw'
import '@tldraw/tldraw/tldraw.css'
import { CardShapeTool, CardShapeUtil } from '../board/CardShape'
import { uiOverrides } from '../board/ui-overrides'
import Sidebar from '../sidebar/Sidebar'
import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import "./Main.css"
import { throttle } from '@tldraw/utils'
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Divider, IconButton, Paper, Toolbar, Tooltip } from '@mui/material'
import EditorHandler from '../editor-handler/EditorHandler'
import { FirebaseApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { WrapperShapeTool, WrapperShapeUtil } from '../board/WrapperShape'
import CallMadeIcon from '@mui/icons-material/CallMade';
import NearMeIcon from '@mui/icons-material/NearMe';
import PanToolIcon from '@mui/icons-material/PanTool';
import { Outlet } from 'react-router-dom'
import CodeManager from '../code/code-editor/CodeManager'
import CustomButton from '../custom-components/CustomButton'
import CodeIcon from '@mui/icons-material/Code';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SettingsIcon from '@mui/icons-material/Settings';
import { FolderShapeTool, FolderShapeUtil } from '../board/FolderShape'
import CropFreeIcon from '@mui/icons-material/CropFree';



const customShapeUtils = [CardShapeUtil, WrapperShapeUtil, FolderShapeUtil]
const customTools = [CardShapeTool, WrapperShapeTool, FolderShapeTool]

interface MainProps {
	app: FirebaseApp
}

const Main = ({
	app
}: MainProps) => {
	const [triggerNewDeployment, setTriggerNewDeployment] = useState(0);
	const [editor, setEditor] = useState<Editor>()
	const [isValid, setIsValid] = useState(true)
	const [currentView, setCurrentView] = useState<"EDITOR" | "CODE" | "SETTINGS">("EDITOR")
	const sidebarWidth = "25"
	const auth = getAuth(app)
	const fs = getFirestore(app)

	const [isAccessKeyPresent, setIsAccessKeyPresent] = useState(false)

	useEffect(() => {
		const docRef = doc(fs, "users", auth.currentUser?.uid!)
		getDoc(docRef).then((d) => {
			setIsAccessKeyPresent(d && d.data() && d.data()!.awsAccessKeyId ? true : false)
		})
	}, [])

	const setAppToState = useCallback((editor: Editor) => {
		setEditor(editor)
		if (editor.getPageShapeIds(editor.currentPage).size === 0) {
			editor.zoomOut()
		} else {
			editor.zoomToContent()
		}
	}, [])


	const [store] = useState(() => createTLStore({
		shapeUtils: [...customShapeUtils, ...defaultShapeUtils],
		history: undefined
	} as TLStoreOptions))
	const [loadingState, setLoadingState] = useState<
		{ status: 'loading' } | { status: 'ready' } | { status: 'error'; error: string }
	>({
		status: 'loading',
	})

	useEffect(() => {
		const getAndUpdateState = async () => {
			setLoadingState({ status: 'loading' })
			const docRef = doc(fs, "state", "editorState-" + auth.currentUser?.uid!)
			// Get persisted data from firestore
			const firebaseSnapshot = await getDoc(docRef)
			const persistedSnapshot = firebaseSnapshot.data()


			if (persistedSnapshot) {
				try {
					if (persistedSnapshot.editorValue) {
						const snapshot = JSON.parse(persistedSnapshot.editorValue)
						store.loadSnapshot(snapshot)
					}
					setLoadingState({ status: 'ready' })
				} catch (error: any) {
					setLoadingState({ status: 'error', error: error.message }) // Something went wrong
				}
			} else {
				setLoadingState({ status: 'ready' }) // Nothing persisted, continue with the empty store
			}

			// Each time the store changes, run the (debounced) persist function
			const cleanupFn = store.listen(
				throttle(() => {
					const snapshot = store.getSnapshot()
					setDoc(docRef, {
						editorValue: JSON.stringify(snapshot)
					}, { merge: true })
				}, 500)
			)

			return () => {
				cleanupFn()
			}
		}
		getAndUpdateState()

	}, [store])

	if (loadingState.status === 'loading') {
		return (
			<div className="tldraw__editor">
				<h2>Loading...</h2>
			</div>
		)
	}

	if (loadingState.status === 'error') {
		return (
			<div className="tldraw__editor">
				<h2>Error!</h2>
				<p>{loadingState.error}</p>
			</div>
		)
	}

	return (
		<>
			<div className='fixed inset-0'>
				{currentView === "EDITOR" &&
					<>
						<div className={'h-full transition-all'}
							style={{
								marginRight: sidebarWidth + "rem"
							}}>
							<Tldraw
								store={store}
								shapeUtils={customShapeUtils as any}
								tools={customTools}
								overrides={uiOverrides}
								onMount={setAppToState}
							>
								<div className='top-2 left-2 absolute z-200'>
									<CustomButton onClick={() => setCurrentView("CODE")} title={"See code"} icon={<CodeIcon />} />
								</div>
								<div className='top-2 left-36 absolute z-200'>
									<Tooltip placement="bottom" title={isValid ? isAccessKeyPresent ? false : "You need to set your AWS Access Key first. You can do that in Settings > Credentials" : "All the errors need to be fixed before you can deploy."}>
										<div>
											<CustomButton
												disabled={!isAccessKeyPresent || !isValid}
												onClick={() => {
													setCurrentView("CODE")
													setTriggerNewDeployment(Date.now())
												}} title={"Deploy to AWS"} icon={<CloudQueueIcon />} />
										</div>
									</Tooltip>
								</div>
								<div className='top-2 right-2 absolute z-200'>
									<Tooltip title={"Settings"} placement='left'>
										<IconButton onClick={() => window.location.href = "/settings"}>
											<SettingsIcon />
										</IconButton>
									</Tooltip>
								</div>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										position: 'absolute',
										bottom: '10px',
										left: "50%",
										transform: "translate(-50%, 0)",
										width: '100%',
										zIndex: "200"
									}}
								>
									<Paper elevation={1} sx={{ backgroundColor: '#fff', borderRadius: '10px', display: 'flex', alignItems: 'center' }} >
										<Tooltip title={"Select"} placement='top'>
											<IconButton onClick={() => editor?.setCurrentTool("select")}>
												<NearMeIcon />
											</IconButton>
										</Tooltip>
										<Divider orientation="vertical" sx={{ height: 25 }} />
										<Tooltip title={"Hand"} placement='top'>
											<IconButton onClick={() => editor?.setCurrentTool("hand")}>
												<PanToolIcon />
											</IconButton>
										</Tooltip>
										<Divider orientation="vertical" sx={{ height: 25 }} />
										<Tooltip title={"Connect"} placement='top'>
											<IconButton onClick={() => editor?.setCurrentTool("arrow")}>
												<CallMadeIcon />
											</IconButton>

										</Tooltip>
										<Divider orientation="vertical" sx={{ height: 25 }} />
										<Tooltip title={"Template"} placement='top'>
											<IconButton onClick={() => editor?.setCurrentTool("folder")}>
												<CropFreeIcon />
											</IconButton>
										</Tooltip>
									</Paper>
								</Box>
							</Tldraw>
						</div>
						<EditorHandler app={app} sidebarWidth={sidebarWidth} editor={editor} setIsValid={setIsValid} />
					</>
				}
				{
					currentView === "CODE" &&
					<CodeManager app={app} setTriggerNewDeployment={setTriggerNewDeployment} setCurrentView={setCurrentView} isValid={isValid} triggerNewDeployment={triggerNewDeployment} />
				}
			</div>
			<Outlet />
		</>
	)
}
export default Main