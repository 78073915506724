import { Editor, TLShapeId, react, useValue } from "@tldraw/tldraw";
import { useEffect, useRef, useState } from "react"
import { MappedService } from "./EditorHandler";
import { ArrowData, ServiceData, arrowsProperties } from "../services/Service";
import { getCommonArrowTypes } from "../utils/arrows";

interface ArrowsHandlerProps {
    editor: Editor | undefined
    editorState: string
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>
    selectedShapeId: string
    arrowsData: ArrowData[]
    arrowsDataRef: React.MutableRefObject<ArrowData[]>
    setArrowsData: (arrowsData: ArrowData[]) => void
    setFsArrowsData: (arrowsData: ArrowData[]) => void
    setFsServiceDataMap: (serviceDataMap: Map<string, MappedService>) => void
    refreshServiceDataMap: () => void
    setEditorBackground: (theme: "light" | "dark") => void

}

const ArrowsHandler = ({
    editor, editorState, serviceDataMapRef, selectedShapeId, arrowsData, arrowsDataRef,
    setFsArrowsData, setArrowsData, setFsServiceDataMap, refreshServiceDataMap, setEditorBackground
}: ArrowsHandlerProps) => {

    const currentArrow: {
        startId: string | undefined,
        endId: string | undefined
    } = useValue("change arrow bindings", () => {
        if (!editor || !selectedShapeId) return {
            startId: undefined,
            endId: undefined
        };
        const shape = editor.getShape(selectedShapeId as TLShapeId);
        let startId: string | undefined, endId: string | undefined
        if (shape?.type === "arrow") {
            if ((shape?.props as any).start.type === "binding") startId = (shape?.props as any).start.boundShapeId
            if ((shape?.props as any).end.type === "binding") endId = (shape?.props as any).end.boundShapeId
        }
        return {
            startId, endId
        }
    }, [editor, selectedShapeId])

    const fullyBoundArrows: {
        id: string,
        startId: string,
        endId: string
    }[] = useValue("fully bound arrows", () => {
        if (!editor) return []
        return Array.from(editor.getPageShapeIds(editor.currentPage)).map((id) => {
            return editor.getShape(id)
        }).filter((shape) => {
            return shape?.type === "arrow" &&
                (shape?.props as any).start.type === "binding" &&
                (shape?.props as any).end.type === "binding" &&
                (shape?.props as any).start.boundShapeId !== (shape?.props as any).end.boundShapeId
        }).map((shape) => {
            return {
                id: shape?.id as string,
                startId: (shape?.props as any).start.boundShapeId,
                endId: (shape?.props as any).end.boundShapeId
            }
        })
    }, [editor])

    useEffect(() => {
        if (!editor || editorState === "" || (!editorState.startsWith("root.arrow") && editorState !== "root.select.dragging_handle")) return;
        setEditorBackground("dark")
        if (selectedShapeId !== "") {
            const selected = editor.selectedShapeIds[0]
            const shape = editor.getShape(selected);
            if ((editor.isIn("arrow.pointing") || editor.isIn("select.dragging_handle")) &&
                shape?.type === "arrow") {
                if ((shape?.props as any).start.type === "binding" && (shape?.props as any).end.type === "binding") {
                    setOutputsToHighlight((shape?.props as any).start.boundShapeId, (shape?.props as any).end.boundShapeId)
                } else {
                    removeOutputsToHighlight()
                }
                if ((shape?.props as any).start.type !== "binding" || !canEmitArrow((shape?.props as any).start.boundShapeId)) {
                    editor.setCurrentTool("select")
                    editor.deleteShape(selected)
                } else {
                    setInputsToHighlight((shape?.props as any).start.boundShapeId)
                }
            } else {
                if (editorState === "root.arrow.idle") {
                    showAllOutputs()
                }
            }
            if (((editor.isIn("select.dragging_handle") ||
                editor.isIn("arrow.pointing")) &&
                shape?.type === "arrow" &&
                (shape?.props as any).start.type === "binding") || editor.isIn("arrow")) {
                highlightShapes()
            } else {
                removeAllHighlights()
            }
        }
        else {
            if (editor && editor.isIn("arrow")) {
                highlightShapes()
                showAllOutputs()
            } else {
                removeAllHighlights()
            }
        }
    }, [editorState, JSON.stringify(currentArrow)])

    useEffect(() => {
        refreshArrows()
    }, [JSON.stringify(fullyBoundArrows)])

    const refreshArrows = () => {
        const arrows = [...arrowsDataRef.current]
        fullyBoundArrows.forEach((arrow) => {
            if (!arrowsDataRef.current.some((d) => {
                return d.shapeId === arrow.id &&
                    d.startId === arrow.startId &&
                    d.endId === arrow.endId
            })) {
                const commonTypes = getCommonArrowTypes(serviceDataMapRef.current, arrow.startId, arrow.endId)
                const filteredArrowProps = arrowsProperties.filter((p) => {
                    return p.type === commonTypes[0] &&
                        p.fromType === serviceDataMapRef.current.get(arrow.startId)?.serviceData.serviceName &&
                        p.toType === serviceDataMapRef.current.get(arrow.endId)?.serviceData.serviceName
                })
                const newArrow = {
                    type: commonTypes[0],
                    shapeId: arrow.id,
                    startId: arrow.startId,
                    endId: arrow.endId,
                    properties: filteredArrowProps.length > 0 ? filteredArrowProps[0].properties.map((p) => {
                        return { ...p, value: p.defaultValue }
                    }) : []
                }
                arrows.push(newArrow)
                serviceDataMapRef.current.get(arrow.startId)?.serviceData.arrowOut.push({
                    arrowData: newArrow,
                    serviceData: serviceDataMapRef.current.get(arrow.endId)?.serviceData!
                })
                serviceDataMapRef.current.get(arrow.endId)?.serviceData.arrowIn.push({
                    arrowData: newArrow,
                    serviceData: serviceDataMapRef.current.get(arrow.startId)?.serviceData!
                })
            }
        })

        const newArrowsData: ArrowData[] = []
        arrows.forEach((d) => {
            if (fullyBoundArrows.some((arrow) => {
                return d.shapeId === arrow.id &&
                    d.endId === arrow.endId &&
                    d.startId === arrow.startId
            })) {
                newArrowsData.push(d)
            }
            else {
                if (serviceDataMapRef.current.get(d.startId)) {
                    serviceDataMapRef.current.get(d.startId)!.serviceData.arrowOut = serviceDataMapRef.current.get(d.startId)?.serviceData.arrowOut.filter((a) => {
                        return a.arrowData !== d
                    })!
                }

                if (serviceDataMapRef.current.get(d.endId)) {
                    serviceDataMapRef.current.get(d.endId)!.serviceData.arrowIn = serviceDataMapRef.current.get(d.endId)?.serviceData.arrowIn.filter((a) => {
                        return a.arrowData !== d
                    })!
                }
            }

        })
        refreshServiceDataMap()

        arrowsDataRef.current = newArrowsData
        setFsArrowsData(newArrowsData)
        setArrowsData(newArrowsData)
    }

    const showAllOutputs = () => {
        serviceDataMapRef.current.forEach((s, key) => {
            const arrowOutTypes = s.serviceData.arrowOutTypes
            editor?.updateShape({
                id: key as TLShapeId, type: s.serviceData.isWrapper ? "wrapper" : "card", props: {
                    inputs: [],
                    transparentInputs: [],
                    outputs: [...arrowOutTypes],
                    transparentOutputs: [],
                }
            })
        })
    }

    const setOutputsToHighlight = (startId: string, endId: string) => {
        const startService = serviceDataMapRef.current.get(startId)?.serviceData!
        const commonTypes = getCommonArrowTypes(serviceDataMapRef.current, startId, endId)
        const nonCommonTypes = startService.arrowOutTypes.filter((t) => { return commonTypes.indexOf(t) === -1 }) || []
        if (JSON.stringify((editor?.getShape(startId as TLShapeId)?.props as any).transparentOutputs) !== JSON.stringify(nonCommonTypes))
            editor?.updateShape({
                id: startId as TLShapeId, type: startService.isWrapper ? "wrapper" : "card", props: {
                    inputs: [],
                    transparentInputs: [],
                    outputs: [...commonTypes, ...nonCommonTypes],
                    transparentOutputs: startId === endId ? [] : nonCommonTypes,
                }
            })
    }
    const setInputsToHighlight = (startId: string) => {
        serviceDataMapRef.current.forEach((service, id) => {
            if (id !== startId) {
                const commonTypes = getCommonArrowTypes(serviceDataMapRef.current, startId, id)
                const nonCommonTypes = service.serviceData.arrowInTypes.filter((t) => { return commonTypes.indexOf(t) === -1 })
                const parentsTypes = service.serviceData.parentsTypes
                if (commonTypes.length > 0) {
                    editor?.updateShape({
                        id: id as TLShapeId, type: editor.getShape(id as TLShapeId)?.type!, props: {
                            inputs: [...commonTypes],
                            transparentInputs: [],
                            outputs: [],
                            transparentOutputs: [],
                        }
                    })
                }
            }
        })
    }

    const removeAllHighlights = () => {
        const shapesIds = editor?.getPageShapeIds(editor.currentPage)
        shapesIds && shapesIds.forEach((id) => {
            const type = editor?.getShape(id)?.type
            editor?.updateShape({ id: id, type: type!, opacity: 1 })
        })
    }

    const removeOutputsToHighlight = () => {
        serviceDataMapRef.current.forEach((service, id) => {
            if (!service.serviceData.isWrapper)
                if ((editor?.getShape(id as TLShapeId)?.props as any).transparentOutputs.length > 0)
                    editor?.updateShape({
                        id: id as TLShapeId, type: "card", props: {
                            transparentOutputs: []
                        }
                    })
        })
    }

    const canEmitArrow = (id: string) => {
        return serviceDataMapRef.current.get(id) ? serviceDataMapRef.current.get(id)!.serviceData.arrowOutTypes.length > 0 : false
    }

    const highlightShapes = () => {
        const setNoOutputTransparent = () => {
            editor?.getPageShapeIds(editor.currentPage).forEach((id) => {
                const shape = editor.getShape(id);
                if (shape && (shape.type !== "card" || serviceDataMapRef.current.get(id)?.serviceData.arrowOutTypes.length === 0)) {
                    setTransparency(shape.id)
                }
            })
        }
        removeAllHighlights()
        if (selectedShapeId) {
            if (editor?.isIn("arrow.idle")) setNoOutputTransparent()
            else {
                const shape = editor!.getShape(selectedShapeId as TLShapeId);
                shape && shape.type === "arrow" && highlight((shape?.props as any).start.boundShapeId, shape.id)
            }
        } else {
            setNoOutputTransparent()
        }
    }

    const highlight = (boundShapeId: string, arrowId: string) => {

        editor?.getPageShapeIds(editor.currentPage).forEach((shapeId) => {
            if (shapeId !== arrowId && (editor.getShape(shapeId)?.type === "arrow" || editor.getShape(shapeId)?.type === "folder")) {
                setTransparency(shapeId)
            }
        })
        serviceDataMapRef.current.forEach((service, key) => {
            if (key !== boundShapeId && getCommonArrowTypes(serviceDataMapRef.current, boundShapeId, key).length === 0) {
                setTransparency(key)
            }
        })
    }

    const setTransparency = (id: string) => {
        editor?.updateShape({ id: id as TLShapeId, type: editor.getShape(id as TLShapeId)?.type || "card", opacity: 0.2 })
    }


    return (<></>)

}
export default ArrowsHandler