import { useState, useEffect } from "react"
import { Argument, ArrowData, Component, ServiceData } from "../../services/Service"
import { ServiceTranslation } from "../../services/Service"
import CodeBox from "./CodeBox";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { CodeArgument, CodeComponent, createCodeText, getArguments, getChangedProperties, getInnerDependsOnProperties } from "../codeWriteUtils";
import { checkArgumentsChange, parseHCL } from "../codeParseUtils";



// Then register the languages you need

interface CodeWriterProps {
    serviceData: ServiceData
    serviceTranslation: ServiceTranslation
    refreshServiceDataMap: () => void
    codeState: CodeComponent[]
    setCodeState: (codeState: CodeComponent[]) => void
    oldProperties: {
        name: string,
        value: string
    }[]
    setOldProperties: (properties: {
        name: string,
        value: string
    }[]) => void
}

const CodeWriter = ({ serviceData, serviceTranslation, codeState, setCodeState, oldProperties, setOldProperties, refreshServiceDataMap }: CodeWriterProps) => {

    const [firstRender, setFirstRender] = useState(codeState.length === 0);
    const [stopPropagatingChanges, setStopPropagatingChanges] = useState(false)
    const [isDataInitialized, setIsDataInitialized] = useState(false)
    const [completeState, setCompleteState] = useState<CodeComponent[]>([])
    const [isEditorFocused, setIsEditorFocused] = useState(false)
    const [codeText, setCodeText] = useState("")
    const [editorValue, setEditorValue] = useState("")

    useEffect(() => {
        const codeComponents = parseHCL(editorValue);
        if (!isDataInitialized) return;
        const alreadyUsedOldComponents: CodeComponent[] = []

        codeComponents.forEach((newCodeComponent) => {
            const oldComponentsArray = codeState.filter((cs) => {
                return cs.component === newCodeComponent.component &&
                    cs.type === newCodeComponent.type &&
                    cs.componentName === newCodeComponent.componentName
            })

            let oldComponent: CodeComponent | undefined = undefined

            const isNew = oldComponentsArray.length === 0

            let found = false

            oldComponentsArray.forEach((o) => {
                if (!found)
                    if (!alreadyUsedOldComponents.some(c => {
                        return c.component === o.component &&
                            c.type === o.type &&
                            c.componentName === o.componentName
                    })) {
                        alreadyUsedOldComponents.push(o)
                        oldComponent = o
                        found = true
                    }
            })

            const completeComponent = completeState.filter((cs) => {
                return cs.component === newCodeComponent.component &&
                    cs.type === newCodeComponent.type &&
                    cs.componentName === newCodeComponent.componentName
            })
            const componentState = oldComponent || newCodeComponent
            if (!componentState.state) {
                componentState.state = completeComponent.length > 0 && (oldComponent || isNew) ? "UNEDITED" : "EDITED"
            }
            newCodeComponent.state = componentState.state === "HIDDEN" ? "UNEDITED" : componentState.state
            newCodeComponent.dependsOnProperties = componentState.dependsOnProperties
            newCodeComponent.state = checkArgumentsChange(newCodeComponent, componentState.arguments, (oldComponent || isNew) && completeComponent.length > 0 ? completeComponent[0].arguments : [], isEditorFocused)
        })
        codeState.forEach((o) => {
            if (!codeComponents.some((a) => {
                return a.component === o.component &&
                    a.type === o.type &&
                    a.componentName === o.componentName
            })) {
                o.state = o.state === "HIDDEN" ? o.state : "DELETED"
                codeComponents.push(o)
            }
        })
        setCodeState(codeComponents)
    }, [editorValue])


    const updatePropertyRecords = () => {
        const arrowInProperties: { name: string; value: string; }[] = [],
            serviceInProperties: { name: string; value: string; }[] = [],
            arrowOutProperties: { name: string; value: string; }[] = [],
            parentProperties: { name: string; value: string; }[] = [],
            serviceOutProperties: {
                name: string,
                value: string
            }[] = []
        serviceData.arrowIn.forEach((a) => {
            const baseName = "arrowIn." + a.arrowData.shapeId
            a.arrowData.properties.forEach((arrowProp) => {
                arrowInProperties.push({
                    name: baseName + "." + arrowProp.name,
                    value: typeof arrowProp.value === "string" ? arrowProp.value : JSON.stringify(arrowProp.value)
                })
            })
            a.serviceData.properties.forEach((p) => {
                serviceInProperties.push({
                    name: "serviceIn." + a.arrowData.startId + "." + p.name,
                    value: typeof p.value === "string" ? p.value : JSON.stringify(p.value)
                })
            })
        })
        serviceData.arrowOut.forEach((a) => {
            const baseName = "arrowOut." + a.arrowData.shapeId
            a.arrowData.properties.forEach((arrowProp) => {
                arrowOutProperties.push({
                    name: baseName + "." + arrowProp.name,
                    value: typeof arrowProp.value === "string" ? arrowProp.value : JSON.stringify(arrowProp.value)
                })
            })
            a.serviceData.properties.forEach((p) => {
                serviceOutProperties.push({
                    name: "serviceOut." + a.arrowData.endId + "." + p.name,
                    value: typeof p.value === "string" ? p.value : JSON.stringify(p.value)
                })
            })
        })

        serviceData.parents.forEach((parent) => {
            const baseName = "parent." + parent.shapeId
            parent.serviceData.properties.forEach((p) => {
                parentProperties.push({
                    name: baseName + "." + p.name,
                    value: typeof p.value === "string" ? p.value : JSON.stringify(p.value)
                })
            })
        })


        setOldProperties(
            [...serviceData.properties.map(p => {
                return {
                    name: p.name,
                    value: typeof p.value === "string" ? p.value : JSON.stringify(p.value)
                }
            }),
            ...arrowInProperties,
            ...arrowOutProperties,
            ...serviceInProperties,
            ...serviceOutProperties
            ])
    }

    const removeCodeStateDeletedArgument = (args: CodeArgument[], deletedProperties: string[]): CodeArgument[] => {
        return [
            ...args.filter((a) => {
                return (a.state && a.state === "EDITED") || (a.state && a.state === "DELETED") || !deletedProperties.some(d => {
                    return a.dependsOnProperties?.indexOf(d) === -1
                })
            }).map((a) => {
                return {
                    ...a,
                    value: Array.isArray(a.value) ? removeCodeStateDeletedArgument(a.value, deletedProperties) : a.value
                }
            })
        ]
    }

    const areInnerArgumentsNew = (newArgs: string | Argument[], oldArgs: CodeArgument[]) => {
        if (typeof newArgs === "string") return false
        let value = false
        newArgs.forEach((newArg) => {
            const matchingCodeArgument = oldArgs.filter((cs) => {
                return newArg.name === cs.name
            })
            const isNewArgument = matchingCodeArgument.length === 0
            const codeArgs = isNewArgument ? undefined : matchingCodeArgument[0].value
            if (isNewArgument)
                return true;
            if (Array.isArray(newArg.value))
                value = areInnerArgumentsNew(newArg.value as Argument[], Array.isArray(codeArgs) ? codeArgs : [])
        })
        return value
    }

    useEffect(() => {
        if (stopPropagatingChanges) {
            setStopPropagatingChanges(false)
            return;
        }
        if (!serviceData || !serviceData.properties) return;
        const changedProperties = getChangedProperties(serviceData.properties, serviceData.arrowIn, serviceData.arrowOut, serviceData.parents, oldProperties)
        const completeState = serviceTranslation.components.map((c) => {
            return {
                component: c.component,
                dependsOnProperties: c.dependsOnProperties,
                type: c.type,
                conditionVerified: c.condition,
                componentName: c.componentName,
                arguments: getArguments(c.arguments, changedProperties, firstRender, true)
            }
        })

        setCompleteState(completeState)
        const state = serviceTranslation.components.filter((c) => {

            return (firstRender || changedProperties.some((cp) => {
                return (c.dependsOnProperties && c.dependsOnProperties?.indexOf(cp) !== -1) ||
                    getInnerDependsOnProperties(c.arguments).indexOf(cp) !== -1
            }))
        }).map((c) => {
            return {
                component: c.component,
                dependsOnProperties: c.dependsOnProperties,
                type: c.type,
                conditionVerified: c.condition,
                componentName: c.componentName,
                arguments: getArguments(c.arguments, changedProperties, firstRender)
            }
        })

        setCodeText(createCodeText(state, completeState, codeState, firstRender, serviceTranslation, setCodeState))
        setFirstRender(false)
        updatePropertyRecords()
        setIsDataInitialized(true)
    }, [JSON.stringify(serviceData.properties), JSON.stringify(serviceData.parents.map((p) => {
        return {
            ...p,
            serviceData: {
                ...p.serviceData,
                arrowIn: [],
                arrowOut: []
            }
        }
    }))])

    return (
        <Accordion
            disableGutters
            sx={{
                "&.MuiAccordion-root": {
                    borderRadius: 0,
                    boxShadow: "none",
                },
                '&.Mui-expanded': {
                    boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                }
            }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className=" text-xl">Code box</div>
            </AccordionSummary>
            <AccordionDetails>
                <CodeBox codeText={codeText} editorValue={editorValue} setEditorValue={setEditorValue} setIsEditorFocused={setIsEditorFocused} />
            </AccordionDetails>
        </Accordion>
    )
}
export default CodeWriter