import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { useState } from "react"
import { getFunctions, httpsCallable } from "@firebase/functions";
import { FirebaseApp } from "firebase/app";
import CodeDiff from "./CodeDiff";


interface ModelResponseDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    text: string,
    components: string[]
    app: FirebaseApp
    userRequest: string
}

interface ComponentPair {
    oldCode: string;
    newCode: string;
}


const ModelResponseDialog = ({
    open, setOpen, text, components, app, userRequest
}: ModelResponseDialogProps) => {

    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
    const [showCodeDiff, setShowCodeDiff] = useState<boolean>(false)
    const [currentCodeDiff, setCurrentCodeDiff] = useState<number>(0)
    const [componentArray, setComponentArray] = useState<ComponentPair[]>([])
    const [noChangesAppliedReason, setNoChangesAppliedReason] = useState<string>("")
    const functions = getFunctions(app, "europe-west3");

    const reformulation = text.split("Reformulation:").length > 1 ? (text.split("Reformulation:")[1].split("\nQuestion 1:")[0]).trim() : ""

    const getQuestions = (text: string) => {
        const questions: string[] = []
        text.split("\n").forEach((line) => {
            if (line.startsWith("Question")) {
                questions.push(line.split(":")[1].trim())
            }
        })
        return questions
    }

    const questions = getQuestions(text)
    const [answers, setAnswers] = useState<string[]>(new Array(questions.length).fill(""))

    const onAnswerChange = (answer: string, index: number) => {
        const newAnswers = [...answers]
        newAnswers[index] = answer
        setAnswers(newAnswers)
    }

    const handleClose = () => {
        setIsButtonLoading(false)
        setShowCodeDiff(false)
        setCurrentCodeDiff(0)
        setComponentArray([])
        setNoChangesAppliedReason("")
        setAnswers(new Array(questions.length).fill(""))
        setOpen(false)
    }

    const generateCode = async () => {

        setIsButtonLoading(true)
        const codeGenFunction = httpsCallable<
            any, string>(
                functions, "generatecode", { timeout: 180000 });

        const response = await codeGenFunction({
            userRequest,
            components,
            previousMessage: text,
            userAnswers: answers
        })
        setIsButtonLoading(false)
        setShowCodeDiff(true)
        setComponentArray(extractComponents(response.data))
    }

    function extractComponents(inputText: string) {

        const componentArr: ComponentPair[] = [];

        // Regex to match the "Component X:" and the associated code block
        const regex = /Component\s(\d+):\s*```(hcl)?([^`]*)```/g;
        let match;

        // Match all instances of component code blocks
        while ((match = regex.exec(inputText)) !== null) {
            const componentNumber = parseInt(match[1]) - 1; // -1 to make it zero-based index
            const code = match[3].trim();

            if (components.length <= componentNumber) {
                componentArr.push({
                    oldCode: "",
                    newCode: code
                })
            } else
                if (code !== components[componentNumber]) {
                    componentArr.push({
                        oldCode: components[componentNumber],
                        newCode: code
                    })
                }
        }

        if (componentArr.length === 0) {
            const regex = /No changes applied.\s*Reason:(.*)/g;
            let match;
            if ((match = regex.exec(inputText)) !== null) {
                setNoChangesAppliedReason(match[1].trim())
            }
        }

        return componentArr;
    }


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            //width adapts to content
            maxWidth={showCodeDiff && componentArray.length > 0 ? "md" : "sm"}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                {showCodeDiff ? "Suggested edits" : "Your request"}
            </DialogTitle>
            <DialogContent sx={{ width: "100%" }}>
                {showCodeDiff ? componentArray.length > 0 ?
                    <CodeDiff oldCode={componentArray[currentCodeDiff].oldCode} newCode={componentArray[currentCodeDiff].newCode} /> :
                    "No changes applied.\n" + noChangesAppliedReason :
                    <>
                        {reformulation}
                        {
                            questions.map((question, i) => {
                                return (
                                    <>
                                        <div>
                                            <b>{question}</b>
                                        </div>
                                        <TextField
                                            margin="dense"
                                            key={"answer-" + i}
                                            value={answers[i]}
                                            onChange={(e) => onAnswerChange(e.target.value, i)}
                                            label="Answer"
                                            type="text"
                                            fullWidth />
                                    </>
                                )
                            })
                        }
                    </>
                }

            </DialogContent>
            <DialogActions>
                {showCodeDiff ?
                    <>
                        <Button
                            disabled={currentCodeDiff === 0}
                            onClick={() => {
                                setCurrentCodeDiff(currentCodeDiff - 1)
                            }
                            }>Previous</Button>
                        <Button
                            disabled={currentCodeDiff === componentArray.length - 1}
                            onClick={() => {
                                if (currentCodeDiff < componentArray.length - 1) {
                                    setCurrentCodeDiff(currentCodeDiff + 1)
                                }
                            }}>Next</Button>
                    </>
                    : <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={generateCode}
                            autoFocus
                            disabled={isButtonLoading}
                            endIcon={isButtonLoading ? <CircularProgress style={{
                                color: "lightgray",
                                height: "20px",
                                width: "20px"
                            }} /> : null}>
                            Proceed
                        </Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ModelResponseDialog