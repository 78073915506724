import { Button, CircularProgress, Tooltip } from "@mui/material"

import { Storage } from '@google-cloud/storage';
import { MappedService } from "../editor-handler/EditorHandler";
import { CodeComponent, getFileContent } from "../code/codeWriteUtils";
import { FirebaseApp } from "firebase/app";
import { getStorage, ref, uploadBytes, listAll, deleteObject, StorageReference } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { mainContent } from "../code/code-editor/mainContent";
import { terraformTemplate } from "../code/code-editor/FileTree";
import axios from 'axios';
import CustomButton from "../custom-components/CustomButton";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface ConfigDeployerProps {
    destroy: boolean
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>,
    setCodeState: (key: string, newCodeState: CodeComponent[]) => void
    addFileToRoot: (name: string) => void
    fileContents: { [filePath: string]: string }
    setFiles: (newContents: {
        path: string,
        content: string
    }[]) => void
    generalCodeState: { [filePath: string]: CodeComponent[] }
    setOutputText: (text: string) => void
    setIsOutputOpen: (value: boolean) => void
    triggerNewDeployment: number
    setTriggerNewDeployment: (value: number) => void
    isDataInitialized: boolean
    app: FirebaseApp
    isValid: boolean
    disableButtons: boolean
    setDisableButtons: (value: boolean) => void
}

const ConfigDeployer = ({
    destroy,
    serviceDataMapRef,
    disableButtons,
    setDisableButtons,
    setCodeState,
    addFileToRoot,
    setOutputText,
    isDataInitialized,
    setIsOutputOpen,
    triggerNewDeployment,
    setTriggerNewDeployment,
    fileContents,
    setFiles,
    generalCodeState,
    isValid,
    app
}: ConfigDeployerProps) => {
    const storage = getStorage(app);
    const auth = getAuth(app);
    const fs = getFirestore(app);
    const [isUploading, setIsUploading] = useState(false)
    const [isApplying, setIsApplying] = useState(false)
    const [isAccessKeyPresent, setIsAccessKeyPresent] = useState(false)
    const outputInterval = useRef<NodeJS.Timer>()

    useEffect(() => {
        const docRef = doc(fs, "users", auth.currentUser?.uid!)
        getDoc(docRef).then((d) => {
            setIsAccessKeyPresent(d && d.data() && d.data()!.awsAccessKeyId ? true : false)
        })
    }, [])

    useEffect(() => {
        if (triggerNewDeployment === 0 || !fileContents["/root/main.tf"] || !isDataInitialized || destroy) return;
        setTriggerNewDeployment(0)
        generateAndUploadFiles()
    }, [triggerNewDeployment, fileContents])

    async function triggerCloudRunService() {
        try {
            const response = await axios.post('https://terraform-runner-2js36x37va-oc.a.run.app/' + (destroy ? 'destroy-terraform' : 'run-terraform'),
                {
                    "user_id": auth.currentUser?.uid,
                    "bucket_name": "nocode-cloud-infra-dev.appspot.com",
                    "files_path": auth.currentUser?.uid + "/root"
                }
            );
            const filesToSet = []

            if (response.data.terraform_state) {
                !terraformTemplate.root["terraform.tfstate"] && addFileToRoot("terraform.tfstate")
                filesToSet.push({ path: "/root/terraform.tfstate", content: response.data.terraform_state })
            }

            if (response.data.terraform_state) {
                !terraformTemplate.root[".terraform.lock.hcl"] && addFileToRoot(".terraform.lock.hcl")
                filesToSet.push({ path: "/root/.terraform.lock.hcl", content: response.data.terraform_lock })
            }
            filesToSet.length > 0 && setFiles(filesToSet)
            clearInterval(outputInterval.current)
            outputInterval.current = undefined
            setOutputText([response.data.terraform_init_stdout, response.data.terraform_init_stderr, response.data.terraform_result_stdout, response.data.terraform_result_stderr].join("\n\n"))

        } catch (error) {
            console.error(`Error: ${error}`);
        }
    }

    const setApplyingOutput = () => {
        let value = '\\'
        setOutputText((destroy ? "\nDestroying infrastructure..." : "\nDeploying to AWS...") + value)
        outputInterval.current = setInterval(() => {
            switch (value) {
                case "\\":
                    value = "|"
                    break;
                case "|":
                    value = "/"
                    break;
                case "/":
                    value = "-"
                    break;
                case "-":
                    value = "\\"
            }
            setOutputText((destroy ? "\nDestroying infrastructure..." : "\nDeploying to AWS...") + value)
        }, 300)
    }

    async function deleteAllFiles(storageRef: StorageReference) {
        const { items, prefixes } = await listAll(storageRef)
        await Promise.all(items.map(async (item) => {
            await deleteObject(item)
        }));
        await Promise.all(prefixes.map(async (prefix) => {
            await deleteAllFiles(prefix)
        }));
    }

    async function addFile(path: string, content: string) {
        const storageRef = ref(storage, auth.currentUser?.uid + path);
        const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
        await uploadBytes(storageRef, blob).catch((e) => console.error(e.message));
    }

    async function traverseAndGenerateFiles(obj: any, currentPath: string = '') {
        for (let key in obj) {
            let newPath = `${currentPath}/${key}`;
            if (obj[key] === null) {
                let textToAdd: string[]
                if (fileContents[newPath]) {
                    textToAdd = [fileContents[newPath]]
                } else {
                    const { newCodeText } = getFileContent(serviceDataMapRef, newPath, setCodeState, generalCodeState[newPath]);
                    textToAdd = newCodeText
                }
                await addFile(newPath, textToAdd.join("\n\n"));
            } else {
                await traverseAndGenerateFiles(obj[key], newPath);
            }
        }
    }

    async function generateAndUploadFiles() {
        if (!isDataInitialized) return;
        setDisableButtons(true)
        const baseRef = ref(storage, auth.currentUser?.uid);
        setIsUploading(true)
        await deleteAllFiles(baseRef).catch((e) => console.error(e))
        await traverseAndGenerateFiles(terraformTemplate);
        setIsUploading(false)
        setIsApplying(true)
        setIsOutputOpen(true)
        setApplyingOutput()

        await triggerCloudRunService()

        setIsApplying(false)
        setDisableButtons(false)
    }

    return (
        <Tooltip placement="bottom" title={isValid ? isAccessKeyPresent ? false : "You need to set your AWS Access Key first. You can do that in Settings > Credentials" : "All the errors need to be fixed before you can " + (destroy ? "destroy." : "deploy.")}>
            <div>
                <CustomButton darkMode title={isUploading ? "Uploading files" : isApplying ? destroy ? "Destroying" : "Deploying changes" : destroy ? "Destroy infrastructure" : "Deploy to AWS"}
                    icon={isUploading || isApplying ? <CircularProgress style={{
                        color: "lightgray",
                        height: "20px",
                        width: "20px"
                    }} /> : destroy ? <HighlightOffIcon /> : <CloudQueueIcon />} disabled={isUploading || isApplying || !isAccessKeyPresent || !isValid || disableButtons} onClick={() => generateAndUploadFiles()} />
            </div>
        </Tooltip>
    )
}

export default ConfigDeployer