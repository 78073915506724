import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { FirebaseApp } from "firebase/app";
import { MappedService } from "../editor-handler/EditorHandler";
import { CodeComponent, getFileCodeBlock } from "../code/codeWriteUtils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { enqueueSnackbar } from "notistack";
import ModelResponseDialog from "../ai/ModelResponseDialog";


interface AIPromptFormProps {
    app: FirebaseApp
    setCodeStateForResource: (codeState: CodeComponent[], resource: string) => void
    serviceDataMap: Map<string, MappedService>
    currentShapeId: string
}

const AIPromptForm = ({
    app, setCodeStateForResource, serviceDataMap, currentShapeId
}: AIPromptFormProps) => {
    const [text, setText] = useState<string>("")
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
    const [openModelResponseDialog, setOpenModelResponseDialog] = useState<boolean>(false)
    const [components, setComponents] = useState<string[]>([])
    const [responseText, setResponseText] = useState<string>("")

    const functions = getFunctions(app, "europe-west3");

    const reformulate = async () => {
        const components = Array.from(serviceDataMap).map(([shapeId, mappedService]) => {
            return getFileCodeBlock(mappedService,
                (codeState: CodeComponent[]) => setCodeStateForResource(codeState, shapeId),
            ).join("\n\n")
        })
        setComponents(components)
        setIsButtonLoading(true)
        const reformulateFunction = httpsCallable<
            any, string>(
                functions, "reformulate");

        const response = await reformulateFunction({ userRequest: text, components })
        setResponseText(response.data)
        setOpenModelResponseDialog(true)
        setIsButtonLoading(false)
    }

    return (
        <>
            <Accordion
                disableGutters
                //defaultExpanded
                sx={{
                    "&.MuiAccordion-root": {
                        borderRadius: 0,
                        boxShadow: "none",
                    },
                    '&.Mui-expanded': {
                        boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                    }
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className=" text-xl">Ask Inkdrop AI</div>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField label={"Your request"}
                        multiline
                        minRows={3}
                        maxRows={5}
                        sx={{ width: "100%" }} value={text} onChange={(e) => setText(e.target.value)} />
                    <Button
                        sx={{ marginTop: "1rem" }}
                        variant="outlined"
                        disabled={isButtonLoading}
                        endIcon={isButtonLoading ? <CircularProgress style={{
                            color: "lightgray",
                            height: "20px",
                            width: "20px"
                        }} /> : null}
                        onClick={() => reformulate()}>
                        Generate
                    </Button>
                </AccordionDetails>
            </Accordion>
            <ModelResponseDialog open={openModelResponseDialog} setOpen={setOpenModelResponseDialog} text={responseText} userRequest={text} components={components} app={app} />
        </>
    )
}

export default AIPromptForm;