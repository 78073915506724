import { Box2d, Editor, TLShapeId, useValue } from "@tldraw/tldraw"
import { useEffect } from "react"
import { MappedService } from "./EditorHandler"
import { ArrowData, ServiceData } from "../services/Service"
import { getCommonArrowTypes } from "../utils/arrows"

interface SelectionHandlerProps {
    editorState: string
    editor: Editor | undefined
    serviceDataMap: Map<string, MappedService>
    setEditorBackground: (theme: "light" | "dark") => void
    shapeIds: string[]
    isDataInitialized: boolean
}
const SelectionHandler = ({
    editor, serviceDataMap, editorState, setEditorBackground, shapeIds, isDataInitialized
}: SelectionHandlerProps) => {

    const selectedShapesIds = useValue("shape selection", () => {
        if (!editor) return [];
        return [...editor.selectedShapeIds]
    }, [editor])


    useEffect(() => {
        if (!editor || !isDataInitialized || editorState.startsWith("root.arrow") || editorState === "root.select.dragging_handle" || editorState === "root.select.translating") return;

        if (selectedShapesIds.length === 1) {
            const shape = editor.getShape(selectedShapesIds[0])
            if (shape?.type === "arrow" && (
                (shape?.props as any).start.type !== "binding" || (shape?.props as any).end.type !== "binding" ||
                (shape?.props as any).start.boundShapeId === (shape?.props as any).end.boundShapeId ||
                getCommonArrowTypes(serviceDataMap, (shape?.props as any).start.boundShapeId, (shape?.props as any).end.boundShapeId).length === 0
            )) {
                editor.deleteShape(selectedShapesIds[0])
            }
        }


        serviceDataMap.forEach((s, key) => {
            removeNonConnectedProperties(s.serviceData, key)

        })
        setEditorBackground("light")
        removeAllTransparency()
        if (selectedShapesIds.length > 0 && !(selectedShapesIds.length === 1 && editor.getShape(selectedShapesIds[0])?.type === "folder")) {
            setEditorBackground("dark")
            highlightSelection(selectedShapesIds)

            selectedShapesIds.forEach((selectedShapeId) => {
                const mappedService = serviceDataMap.get(selectedShapeId)
                if (mappedService) {
                    showAllProperties(mappedService.serviceData, selectedShapeId)
                }
            })
        }
    }, [JSON.stringify(selectedShapesIds), editor, editorState])

    const highlightSelection = (shapeIds: string[]) => {
        const arrowIds = shapeIds.filter(id => {
            const shape = editor?.getShape(id as TLShapeId)
            return shape && shape.type === "arrow"
        })
        const resourceIds = shapeIds.filter(id => {
            return arrowIds.indexOf(id) === -1
        })
        const arrowsHighlights = highlightArrowConnections(arrowIds)
        const resourceHighlights = highlightResourceConnections(resourceIds)
        const transparentShapes = Array.from(editor?.getPageShapeIds(editor.currentPage) || []).filter((shapeId) => {
            return arrowsHighlights.indexOf(shapeId) === -1 && resourceHighlights.indexOf(shapeId) === -1
        })
        transparentShapes.forEach((tId) => {
            editor?.updateShape({ id: tId, type: editor.getShape(tId)!.type, opacity: 0.2 })
        })
    }

    const highlightArrowConnections = (arrowIds: string[]) => {
        const nonTransparentShapesIds = [...arrowIds]
        arrowIds.forEach((arrowId) => {
            serviceDataMap.forEach((m, key) => {
                if ((m.serviceData.arrowIn.some(({ arrowData }) => {
                    return arrowData.shapeId === arrowId
                }) || m.serviceData.arrowOut.some(({ arrowData }) => {
                    return arrowData.shapeId === arrowId
                })) && nonTransparentShapesIds.indexOf(key) === -1) {
                    nonTransparentShapesIds.push(key)
                }
            })
        })
        return nonTransparentShapesIds
    }

    const highlightResourceConnections = (ids: string[]) => {
        const nonTransparentShapesIds = [...ids]
        ids.forEach(id => {
            const mappedService = serviceDataMap.get(id)
            if (mappedService) {
                const serviceData = mappedService.serviceData
                serviceData.arrowIn.forEach(({ arrowData }) => {
                    if (nonTransparentShapesIds.indexOf(arrowData.shapeId) === -1) {
                        nonTransparentShapesIds.push(arrowData.shapeId)
                    }
                    if (nonTransparentShapesIds.indexOf(arrowData.startId) === -1)
                        nonTransparentShapesIds.push(arrowData.startId)
                })
                serviceData.arrowOut.forEach(({ arrowData }) => {
                    if (nonTransparentShapesIds.indexOf(arrowData.shapeId) === -1) {
                        nonTransparentShapesIds.push(arrowData.shapeId)
                    }
                    if (nonTransparentShapesIds.indexOf(arrowData.endId) === -1)
                        nonTransparentShapesIds.push(arrowData.endId)
                })
                serviceData.parents.forEach(({ shapeId }) => {
                    if (nonTransparentShapesIds.indexOf(shapeId) === -1)
                        nonTransparentShapesIds.push(shapeId)
                })
                serviceDataMap.forEach((m, key) => {
                    if (m.serviceData.parents.some((p) => {
                        return p.shapeId === id
                    }) && nonTransparentShapesIds.indexOf(key) === -1)
                        nonTransparentShapesIds.push(key)
                })
            }
        })
        return nonTransparentShapesIds
    }

    const removeAllTransparency = () => {
        Array.from(editor?.getPageShapeIds(editor.currentPage) || []).forEach((id) => {
            editor?.updateShape({ id: id, type: editor.getShape(id)!.type, opacity: 1 })
        })
    }

    const getConnectedArrowTypes = (arrows: { serviceData: ServiceData; arrowData: ArrowData }[]) => {
        const arrowTypes: string[] = []
        arrows.forEach(({ arrowData }) => {
            if (arrowTypes.indexOf(arrowData.type) === -1 && shapeIds.indexOf(arrowData.shapeId) !== -1) {
                if (arrowData.type)
                    arrowTypes.push(arrowData.type)
            }
        })
        return arrowTypes
    }

    const getConnectedParentTypes = (parents: { serviceData: ServiceData; shapeId: string }[]) => {
        const parentTypes: string[] = []

        parents.forEach(({ serviceData, shapeId }) => {
            if (parentTypes.indexOf(serviceData.serviceName) === -1 && shapeIds.indexOf(shapeId) !== -1) {
                parentTypes.push(serviceData.serviceName)
            }
        })
        return parentTypes
    }

    const getNonConnectedParentTypes = (parentTypes: string[], parents: { serviceData: ServiceData; shapeId: string }[]) => {
        return parentTypes.filter((parentType) => {
            return !parents.some(({ serviceData }) => {
                return serviceData.serviceName === parentType
            })
        })
    }

    const getNonConnectedArrowTypes = (arrowTypes: string[], arrows: { serviceData: ServiceData; arrowData: ArrowData }[]) => {
        return arrowTypes.filter((arrowType) => {
            return !arrows.some(({ arrowData }) => {
                return arrowData.type === arrowType
            })
        })
    }

    const removeNonConnectedProperties = (serviceData: ServiceData, id: string) => {
        const connectedArrowInTypes = getConnectedArrowTypes(serviceData.arrowIn)
        const connectedParentTypes = getConnectedParentTypes(serviceData.parents)
        const connectedArrowOutTypes = getConnectedArrowTypes(serviceData.arrowOut)
        const newProps = {
            inputs: [...connectedArrowInTypes, ...connectedParentTypes],
            outputs: connectedArrowOutTypes,
            transparentInputs: [],
            transparentOutputs: [],
        }
        editor?.updateShape({
            id: id as TLShapeId, type: serviceData.isWrapper ? "wrapper" : "card", props: newProps
        })
    }

    const showAllProperties = (serviceData: ServiceData, id: string) => {
        const connectedArrowInTypes = getConnectedArrowTypes(serviceData.arrowIn)
        const connectedParentTypes = getConnectedParentTypes(serviceData.parents)
        const connectedArrowOutTypes = getConnectedArrowTypes(serviceData.arrowOut)
        const nonConnectedArrowInTypes = getNonConnectedArrowTypes(serviceData.arrowInTypes, serviceData.arrowIn)
        const nonConnectedParentTypes = getNonConnectedParentTypes(serviceData.parentsTypes, serviceData.parents)
        const nonConnectedArrowOutTypes = getNonConnectedArrowTypes(serviceData.arrowOutTypes, serviceData.arrowOut)
        const newProps = {
            inputs: [...connectedArrowInTypes, ...connectedParentTypes, ...nonConnectedArrowInTypes, ...nonConnectedParentTypes],
            outputs: [...connectedArrowOutTypes, ...nonConnectedArrowOutTypes],
            transparentInputs: [...nonConnectedArrowInTypes, ...nonConnectedParentTypes],
            transparentOutputs: nonConnectedArrowOutTypes,
        }
        editor?.updateShape({
            id: id as TLShapeId, type: serviceData.isWrapper ? "wrapper" : "card", props: newProps
        })
    }

    return (<></>)
}
export default SelectionHandler