import { MappedService } from "../editor-handler/EditorHandler";

export const getCommonArrowTypes = (serviceDataMap: Map<string, MappedService>, startId: string, endId: string) => {
    const typesStart = serviceDataMap.get(startId)?.serviceData.arrowOutTypes;
    const typesEnd = serviceDataMap.get(endId)?.serviceData.arrowInTypes;
    const commonTypes: string[] = []
    typesStart?.forEach((s) => {
        if (typesEnd?.indexOf(s) !== -1)
            commonTypes.push(s)
    })
    return commonTypes
}