import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { deleteObject, getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import { Editor, TLShape, TLShapeId } from "@tldraw/tldraw";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { assignPropsToServiceDataMap, composeArrowsData, composeServiceDataMap } from "../utils/firestoreOps";
import { ArrowData, services } from "../services/Service";
import { getAvailableServiceName } from "../utils/naming";
import { MappedService } from "../editor-handler/EditorHandler";
import { getShapesDataCopy } from "../utils/shapes";
import DeleteIcon from '@mui/icons-material/Delete';
import { enqueueSnackbar } from "notistack";


interface TemplatesListProps {
    app: FirebaseApp;
    editor: Editor | undefined
    serviceDataMap: Map<string, any>
    refreshServiceDataMap: () => void
    refreshArrowsData: () => void
    refreshTemplatesTimestamp: number
    arrowsData: ArrowData[]
    searchTerm: string
}

const TemplatesList = ({ app, editor, serviceDataMap, refreshServiceDataMap, searchTerm, arrowsData, refreshTemplatesTimestamp }: TemplatesListProps) => {
    const [templateData, setTemplateData] = useState<{
        url: string;
        folderId: string;
        folderName: string;
    }[]>([]);
    const [hoveredTemplateIndex, setHoveredTemplateIndex] = useState<number>(-1);

    useEffect(() => {
        if (!app) return;
        // Retrieve SVGs from Cloud Storage and set imageUrls state
        const fetchImages = async () => {
            const storage = getStorage(app);
            const auth = getAuth(app);
            // Here you should fetch the list of file paths from your database
            const storageRef = ref(storage, `templates/${auth.currentUser?.uid}`);

            const { items, prefixes } = await listAll(storageRef)

            const templateInfo = await Promise.all(
                items.map(async (item) => {
                    const docRef = doc(getFirestore(app), `userTemplates/${getAuth(app).currentUser?.uid}`);
                    const foldersData = (await getDoc(docRef)).data();
                    if (!foldersData || !foldersData.templates) return {
                        url: "",
                        folderId: "",
                        folderName: ""
                    };
                    const folderName = foldersData.templates.find((t: any) => t.folder === item.fullPath.split("/")[2].replace(".svg", "")).folderName;
                    return {
                        url: await getDownloadURL(item),
                        folderId: item.fullPath.split("/")[2].replace(".svg", ""),
                        folderName: folderName as string
                    }
                })
            );
            setTemplateData(templateInfo);
        };

        fetchImages();
    }, [app, refreshTemplatesTimestamp]);


    const getChildrenShapes = (children: any[], point: { x: number, y: number }, serviceData: any, parents: any[], arrowsTemp: any[], folderId: TLShapeId) => {
        const templateServiceDataMap = composeServiceDataMap(serviceData)
        const templateArrowsData: ArrowData[] = arrowsTemp ? composeArrowsData(arrowsTemp) : []
        assignPropsToServiceDataMap(templateArrowsData, templateServiceDataMap, parents, true)
        const shapeIds = children.map((child) => {
            return child.id
        })
        const { newServices, newArrows } = getShapesDataCopy(shapeIds, templateServiceDataMap, templateArrowsData, serviceDataMap)
        //end func
        newServices.forEach(({ newId, oldId, mappedService }) => {
            serviceDataMap.set(newId, mappedService)
            const child = children.filter((s) => { return s.id === oldId })[0]
            const service = services.filter((s) => { return s.serviceName === mappedService.serviceData.serviceName })[0]
            editor?.createShape({
                type: templateServiceDataMap.get(child.id)?.serviceData.isWrapper ? "wrapper" : "card",
                props: {
                    backgroundColor: service.backgroundColor,
                    borderColor: service.borderColor,
                    name: mappedService.serviceData.name,
                    ...(templateServiceDataMap.get(child.id)?.serviceData.isWrapper ? {
                        w: child.width,
                        h: child.height
                    } : {})
                },
                x: point.x + child.relativePositionX,
                y: point.y + child.relativePositionY,
                id: newId as TLShapeId
            })
        })
        refreshServiceDataMap()

        newArrows.forEach(({ newId, oldId, arrowData }) => {
            arrowsData.push(arrowData)
            const child = children.filter((s) => { return s.id === oldId })[0]
            const startBindingShapeId = newServices.filter((s) => { return s.oldId === child.start.boundShapeId })[0].newId
            const endBindingShapeId = newServices.filter((s) => { return s.oldId === child.end.boundShapeId })[0].newId


            editor?.createShape({
                type: "arrow",
                x: point.x + child.relativePositionX,
                y: point.y + child.relativePositionY,
                props: {
                    start: {
                        ...child.start,
                        boundShapeId: startBindingShapeId
                    },
                    end: {
                        ...child.end,
                        boundShapeId: endBindingShapeId
                    }
                },
                id: newId as TLShapeId
            })
        })
        setTimeout(() => {
            editor?.setSelectedShapes([...newServices.map(({ newId }) => { return newId as TLShapeId }), ...newArrows.map(({ newId }) => { return newId as TLShapeId }), folderId])
        }, 1)
    }

    const handleDragStart = async (e: any, url: string) => {
        //e.dataTransfer.setDragImage(<img src={url} style={{ width: '100%', height: '150px' }} />, 0, 0);
    }

    const handleDragEnd = async (e: any, url: string, folderId: string) => {
        if (!editor) return;

        const docRef = doc(getFirestore(app), `userTemplates/${getAuth(app).currentUser?.uid}`);
        const foldersData = (await getDoc(docRef)).data();
        if (!foldersData || !foldersData.templates) return;
        const template = foldersData.templates.find((t: any) => t.folder === folderId);
        if (!template) return;


        const xPosition = e.clientX; // get the x position
        const windowWidth = window.innerWidth; // get the width of the window

        // compute the distance from the right
        const distanceFromRight = windowWidth - xPosition;
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        // convert pixels to rem
        const distanceFromRightRem = distanceFromRight / rootFontSize;
        if (distanceFromRightRem < 25) return;

        //const serviceName = getAvailableName(service.defaultName, serviceDataMap);
        const point = editor.screenToPage({ x: e.clientX, y: e.clientY });
        const folderShape = {
            id: "shape:folder" + Date.now() as TLShapeId,
            type: "folder",
            x: point.x,
            y: point.y,
            props: {
                name: template.folderName,
                w: template.width,
                h: template.height
            }
        }

        editor.createShape(folderShape);
        getChildrenShapes(template.children, point, template.serviceData, template.parents, template.arrowsData, folderShape.id)

        editor.setSelectedShapes([folderShape as TLShape])

    }

    const handleDeleteClick = async (index: number) => {
        if (!app) return;
        const docRef = doc(getFirestore(app), `userTemplates/${getAuth(app).currentUser?.uid}`);
        const foldersData = (await getDoc(docRef)).data();
        if (!foldersData || !foldersData.templates) return;
        const template = foldersData.templates.find((t: any) => t.folder === templateData[index].folderId);
        if (!template) return;
        const templates = foldersData.templates.filter((t: any) => t.folder !== template.folder);
        await setDoc(docRef, {
            templates: templates
        }, { merge: true })
        setTemplateData(templateData.filter((t, i) => { return i !== index }))
        const storage = getStorage(app)
        const storageRef = ref(storage, `templates/${getAuth(app).currentUser?.uid}/${template.folder}.svg`);
        await deleteObject(storageRef)
        enqueueSnackbar("Template deleted", { variant: "success" })
    }



    // A list of images with a title below them, divided in two columns
    return (
        <Grid container spacing={2}>
            {templateData.filter((data) => {
                return searchTerm === "" || data.folderName.toLowerCase().startsWith(searchTerm.toLowerCase())
            }).map((data, index) => (
                <Grid item xs={6} key={index}
                    onMouseEnter={(e) => { setHoveredTemplateIndex(index) }}
                    onMouseLeave={(e) => { setHoveredTemplateIndex(-1) }}
                    onDragStart={(e) => handleDragStart(e, data.url)}
                    onDragEnd={(e) => handleDragEnd(e, data.url, data.folderId)}
                >
                    <div className="cursor-grab">
                        <img src={data.url} alt={`Template ${index}`} style={{ width: '100%', height: '150px' }} />
                        <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                            {data.folderName}
                            {hoveredTemplateIndex === index ?
                                <Tooltip title="Delete template" placement="bottom">
                                    <IconButton
                                        sx={{
                                            width: "30px",
                                            height: "30px",
                                            marginTop: "-3px"
                                        }} onClick={() => handleDeleteClick(index)}>
                                        <DeleteIcon sx={{
                                            width: "20px",
                                            height: "20px",
                                        }} />

                                    </IconButton>
                                </Tooltip> : <DeleteIcon sx={{
                                    width: "30px",
                                    height: "30px",
                                    marginTop: "-3px",
                                    marginLeft: "-30px",
                                    visibility: "hidden"
                                }} />
                            }
                        </Typography>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

export default TemplatesList;