import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import { useState, useEffect } from "react"
import { Editor, react, TLShape, TLShapeId } from "@tldraw/tldraw"
import { ArrowData, ArrowPropertyData, Service, ServicePropertyData, ServiceTranslation, getServiceTranslation, services } from "../services/Service";
import CodeBox from "../code/code-box/CodeBox";
import { MappedService } from "../editor-handler/EditorHandler";
import PropertiesForm from "./PropertiesForm";
import CodeWriter from "../code/code-box/CodeWriter";
import ServiceNameForm from "./ServiceNameForm";
import { CodeComponent } from "../code/codeWriteUtils";
import { getAvailableServiceName } from "../utils/naming";
import ElementName from "./ElementName";
import { getAuth } from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import axios from "axios";
import TemplatesList from "./TemplatesList";
import ServicesList from "./ServicesList";
import AIPromptForm from "./AIPromptForm";


interface SidebarProps {
    app: FirebaseApp
    width: string
    refreshServiceDataMap: () => void
    refreshTemplatesTimestamp: number
    arrowsData: ArrowData[]
    refreshArrowsData: () => void
    editor: Editor | undefined
    selectedShapeId: string
    serviceDataMap: Map<string, MappedService>
    setFsServiceDataMap: (serviceDataMap: Map<string, MappedService>) => void
}

const Sidebar = ({
    app, width, editor, selectedShapeId, refreshTemplatesTimestamp, serviceDataMap, refreshServiceDataMap, arrowsData, refreshArrowsData, setFsServiceDataMap
}: SidebarProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [arrowData, setArrowData] = useState<ArrowData>();
    const [tabValue, setTabValue] = useState(1);

    useEffect(() => {
        setTabValue(selectedShapeId ? 0 : 1)
        const ad = arrowsData.filter((d) => { return d.shapeId === selectedShapeId })
        if (ad.length > 0) {
            setArrowData(ad[0])
        } else {
            setArrowData(undefined)
        }
    }, [selectedShapeId, JSON.stringify(arrowsData.filter((d) => { return d.shapeId === selectedShapeId }))])

    const handleTabChange = (e: any, newValue: number) => {
        setTabValue(newValue)
        setSearchTerm("")
    }

    const setServiceDataMapValue = (property: ServicePropertyData, newValue: any) => {
        property.value = newValue
        refreshServiceDataMap()
    };

    const setArrowDataPropertyValue = (property: ArrowPropertyData, newValue: any) => {
        property.value = newValue
        refreshArrowsData()
    }

    const setCodeStateForResource = (codeState: CodeComponent[], key: string) => {
        const sdm = serviceDataMap.get(key)
        sdm!.codeState! = JSON.parse(JSON.stringify(codeState))
        refreshServiceDataMap()
    }

    const setCodeState = (codeState: CodeComponent[]) => {
        const sdm = serviceDataMap.get(selectedShapeId)
        sdm!.codeState! = codeState
        refreshServiceDataMap()
    }

    const setOldProperties = (properties: {
        name: string,
        value: string
    }[]) => {
        const sdm = serviceDataMap.get(selectedShapeId)
        sdm!.oldProperties! = properties
        refreshServiceDataMap()
    }

    const setServiceName = (desiredName: string) => {
        const name = getAvailableServiceName(desiredName, serviceDataMap, true, selectedShapeId)
        const sd = serviceDataMap.get(selectedShapeId)?.serviceData
        const codeState = serviceDataMap.get(selectedShapeId)?.codeState

        codeState?.forEach(c => {
            const regex = new RegExp("^(.*_)*(" + sd?.name + ")(_.*)*$", "gm")
            const hasMatch = (c.componentName.match(regex) || []).length > 0
            if (hasMatch) {
                c.componentName = c.componentName.replace(regex, "$1" + name + "$3")
            }
        })

        const nameProp = sd?.properties.filter((p) => {
            return p.isName === true
        });
        if (nameProp && nameProp?.length > 0) {
            nameProp[0].value = name
        }
        sd!.name = name;
        editor?.updateShape({ id: selectedShapeId as TLShapeId, type: "card", props: { name: name } })
        refreshServiceDataMap()
    }

    return (
        <Drawer
            anchor={"right"}
            variant="persistent"
            open={true}
        >
            <div
                className=" overflow-hidden"

                style={{
                    width: width + "rem"
                }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab disabled={!selectedShapeId} label="Properties" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                        <Tab label="Resources" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                    </Tabs>
                </Box>
                <div style={{ overflowY: "auto", height: "calc(100% - 48px)" }} >
                    <div

                        role="tabpanel"
                        hidden={tabValue !== 0}
                        id={`simple-tabpanel-0`}
                        aria-labelledby={`simple-tab-0`}
                    >
                        {tabValue === 0 && (
                            <Box>
                                {serviceDataMap.get(selectedShapeId) &&
                                    <>
                                        <ServiceNameForm key={selectedShapeId + "-name-form"} serviceData={serviceDataMap.get(selectedShapeId)?.serviceData!} serviceName={serviceDataMap.get(selectedShapeId)?.serviceData!.name!} setServiceName={setServiceName} serviceType={serviceDataMap.get(selectedShapeId)?.serviceData.serviceName!} />
                                        <PropertiesForm key={selectedShapeId + "-props-form"} app={app} data={serviceDataMap.get(selectedShapeId)?.serviceData!} properties={serviceDataMap.get(selectedShapeId)?.serviceData!.properties!} setProperty={setServiceDataMapValue} />
                                        <CodeWriter
                                            key={selectedShapeId + "-code-writer"}
                                            refreshServiceDataMap={refreshServiceDataMap}
                                            codeState={serviceDataMap.get(selectedShapeId)!.codeState}
                                            oldProperties={serviceDataMap.get(selectedShapeId)!.oldProperties}
                                            setOldProperties={setOldProperties}
                                            setCodeState={setCodeState}
                                            serviceData={serviceDataMap.get(selectedShapeId)!.serviceData}
                                            serviceTranslation={getServiceTranslation(serviceDataMap.get(selectedShapeId)?.serviceData!)} />
                                        <AIPromptForm app={app} currentShapeId={selectedShapeId} serviceDataMap={serviceDataMap} setCodeStateForResource={setCodeStateForResource} />
                                    </>
                                }
                                {
                                    editor?.getShape(selectedShapeId as TLShapeId)?.type === "arrow" && arrowData &&
                                    <>
                                        <ElementName error={false} errorMessages={[]} name={arrowData?.type!} subtitle={
                                            serviceDataMap.get(arrowData?.startId!)?.serviceData.name + " → " + serviceDataMap.get(arrowData?.endId!)?.serviceData.name
                                        } />
                                        <PropertiesForm
                                            app={app}
                                            data={arrowData!}
                                            properties={arrowData ? arrowData.properties : []}
                                            setProperty={setArrowDataPropertyValue}
                                        />
                                    </>
                                }
                                {
                                    editor?.getShape(selectedShapeId as TLShapeId)?.type === "folder" &&
                                    <>
                                        <ServiceNameForm
                                            serviceName={(editor.getShape(selectedShapeId as TLShapeId)?.props as any).name}
                                            setServiceName={(name: string) => {
                                                const shape = editor.getShape(selectedShapeId as TLShapeId)!
                                                editor.updateShape({
                                                    ...shape,
                                                    props: {
                                                        ...shape.props,
                                                        name: name
                                                    }
                                                })
                                            }}
                                            serviceType={"Template"} />
                                    </>
                                }
                                <div className="h-10" />
                            </Box>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={tabValue !== 1}
                        id={`simple-tabpanel-1`}
                        aria-labelledby={`simple-tab-1`}
                    >
                        {tabValue === 1 && (
                            <Box sx={{ p: 3 }}>
                                <TextField
                                    className="w-full"
                                    id="outlined-basic"
                                    label="Search services and templates"
                                    variant="outlined"
                                    onChange={event => {
                                        setSearchTerm(event.target.value);
                                    }}
                                />
                                <Accordion
                                    disableGutters
                                    defaultExpanded
                                    sx={{
                                        "&.MuiAccordion-root": {
                                            borderRadius: 0,
                                            boxShadow: "none",
                                        },
                                        '&.Mui-expanded': {
                                            boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                                        }
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <div className=" text-xl">Your templates</div>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ maxHeight: "400px", overflow: "scroll" }}>
                                        <TemplatesList refreshTemplatesTimestamp={refreshTemplatesTimestamp} app={app} editor={editor} serviceDataMap={serviceDataMap} searchTerm={searchTerm} refreshArrowsData={refreshArrowsData} refreshServiceDataMap={refreshServiceDataMap} arrowsData={arrowsData} />
                                    </AccordionDetails>
                                </Accordion>
                                <ServicesList editor={editor} serviceDataMap={serviceDataMap} setFsServiceDataMap={setFsServiceDataMap} searchTerm={searchTerm} />
                            </Box>
                        )}
                    </div>
                </div>
            </div>
        </Drawer >
    )
}

export default Sidebar;