import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material"
import { MappedService } from "../editor-handler/EditorHandler"
import { useState, useEffect, ChangeEvent } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ArrowData, ArrowPropertyData, ServiceData, ServicePropertyData } from "../services/Service"
import { validateAwsImageUri, validateDockerImageNotAws } from "../services/aws/validators/generalValidator"
import axios from "axios"
import { getAuth } from "firebase/auth"
import { FirebaseApp } from "firebase/app"
import { useSnackbar } from "notistack"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import DeleteIcon from "@mui/icons-material/Delete"


interface PropertiesFormProps {
    properties: any[],
    setProperty: (property: any, newValue: any) => void
    data: any
    app: FirebaseApp
}

const PropertiesForm = ({ properties, setProperty, data, app }: PropertiesFormProps) => {

    const [loadingDockerImageIndex, setLoadingDockerImageIndex] = useState(-1)
    const [isAccessKeyPresent, setIsAccessKeyPresent] = useState(false)

    const { enqueueSnackbar } = useSnackbar();


    const handleChangeInput = (propertyIndex: number, lineIndex: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const values = [...Object.entries(properties![propertyIndex].value).map(([name, value]) => ({ name, value }))];

        if (event.target.name === "key") {
            if (values[lineIndex]) {
                values[lineIndex].name = event.target.value;
            } else {
                values[lineIndex] = { name: event.target.value, value: '' };
            }
            const nameCount = values.reduce((acc, curr) => curr.name === event.target.value ? acc + 1 : acc, 0);
            if (nameCount > 1) {
                alert('Two keys are equal. Please correct them.');
                return;
            }
        } else {
            if (values[lineIndex]) {
                values[lineIndex].value = event.target.value;
            } else {
                values[lineIndex] = { name: '', value: event.target.value };
            }
        }

        let newValue = Object.fromEntries(values.filter(item => item.name !== '').map(item => [item.name, item.value]));

        setProperty(properties[propertyIndex], newValue)

    }

    useEffect(() => {
        const auth = getAuth(app)
        const fs = getFirestore(app)
        const docRef = doc(fs, "users", auth.currentUser?.uid!)
        getDoc(docRef).then((d) => {
            setIsAccessKeyPresent(d && d.data() && d.data()!.awsAccessKeyId ? true : false)
        })
    }, [])

    const handlePropertyInputChange = (e: any, index: number) => {
        let value: string | number;
        typeof properties[index].defaultValue === "number" ?
            value = parseInt(e.target.value) : value = e.target.value
        setProperty(properties[index], value)
    };

    const handleMultipleChoiceChange = (e: any, index: number) => {
        setProperty(properties[index], e.target.value)
    }

    const handleDBEntryChange = (propertyIndex: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | (Event & { target: { value: any; name: string } }), lineIndex: number, field: string) => {
        let newDbEntries = [...properties[propertyIndex].value.map((v: any) => {
            return { ...v }
        })];

        newDbEntries[lineIndex][field] = event.target.value;

        // Automatically add a new line when the "name" is filled for the last entry
        if (lineIndex === newDbEntries.length - 1 && field === 'name' && event.target.value !== '') {
            newDbEntries.push({ name: "", type: "string" });
        }

        // Remove last line if name is deleted to empty and it's not the only entry
        if (lineIndex === newDbEntries.length - 2 && field === 'name' && event.target.value === '' && newDbEntries.length > 1) {
            newDbEntries.pop();
        }

        setProperty(properties[propertyIndex], newDbEntries);
    }

    const handleDBKeyChange = (propertyIndex: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | (Event & { target: { value: any; name: string } }), field: string) => {
        let keyAttribute = { ...properties[propertyIndex].value }

        keyAttribute[field] = event.target.value;

        setProperty(properties[propertyIndex], keyAttribute);
    }

    const pushImage = async (imageName: string, propertyIndex: number) => {
        const auth = getAuth(app)

        setLoadingDockerImageIndex(propertyIndex)

        let response: any;

        try {
            response = await axios.post('https://docker-registry-uploader-2js36x37va-oc.a.run.app/push-to-ecr', {
                "user_id": auth.currentUser?.uid,
                "aws_region": "us-west-2",
                "image_name": imageName,
            });
        } catch (error: any) {
            console.error(`Error: ${error}`);
            enqueueSnackbar("Server error. You can not upload this image right now.", { variant: "error" });
        }

        setLoadingDockerImageIndex(-1)


        if (response && response.data.status === "Success") {
            setProperty(properties[propertyIndex], response.data.ecr_image_name)
            enqueueSnackbar("Your image is being uploaded. This usually takes 1-2 minutes.", { variant: "success" });
        }
    }

    const messagesStartingWithIndex = (messages: string[], index: number): string[] => {
        const startingString = index + ": "
        return messages.filter((m) => {
            return m.startsWith(startingString)
        }).map((m) => {
            return m.replace(startingString, "")
        })
    }

    const handleContainerDefinitionChange = (propertyIndex: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | (Event & { target: { value: any; name: string } }), containerIndex: number, field: string, portIndex?: number) => {
        let newContainerDefinitions = [...properties[propertyIndex].value.map((v: any) => {
            return JSON.parse(JSON.stringify(v))
        })];

        if (field === "imageUri") {
            newContainerDefinitions[containerIndex][field] = event.target.value;
        } else {
            newContainerDefinitions[containerIndex].ports[portIndex!][field] = event.target.value;
        }

        // Automatically add a new port mapping when the last one is filled. Both hostPort and containerPort need to be filled.
        if ((field === 'hostPort' && portIndex === newContainerDefinitions[containerIndex].ports.length - 1 && event.target.value !== '' && newContainerDefinitions[containerIndex].ports[portIndex!].containerPort !== '') ||
            (field === 'containerPort' && portIndex === newContainerDefinitions[containerIndex].ports.length - 1 && event.target.value !== '' && newContainerDefinitions[containerIndex].ports[portIndex!].hostPort !== '')) {
            newContainerDefinitions[containerIndex].ports.push({ containerPort: "", hostPort: "" });
        }

        // Automatically remove the port mapping when it is empty and it's not the last one
        if ((field === 'hostPort' && portIndex !== newContainerDefinitions[containerIndex].ports.length - 1 && event.target.value === '' && newContainerDefinitions[containerIndex].ports[portIndex!].containerPort === '') ||
            (field === 'containerPort' && portIndex !== newContainerDefinitions[containerIndex].ports.length - 1 && event.target.value === '' && newContainerDefinitions[containerIndex].ports[portIndex!].hostPort === '')) {
            newContainerDefinitions[containerIndex].ports.splice(portIndex!, 1);
        }

        setProperty(properties[propertyIndex], newContainerDefinitions);
    }

    const addContainer = (propertyIndex: number) => {
        let newContainerDefinitions = [...properties[propertyIndex].value.map((v: any) => {
            return { ...v }
        })];

        newContainerDefinitions.push({ imageUri: "", ports: [{ containerPort: "", hostPort: "" }] })

        setProperty(properties[propertyIndex], newContainerDefinitions);
    }

    const getPropertiesFromArray = (props: ServicePropertyData[]) => {
        return (
            <>
                {props.filter((p) => {
                    return !p.hide && !p.isName && p.condition(data as any)
                }).length > 0 ?
                    props.map((p) => {
                        const index = properties.indexOf(p)
                        return (
                            <>
                                {
                                    !p.condition(data as any) || p.isName || p.hide ?
                                        <></> :
                                        <>
                                            {!p.containerDefinition &&
                                                <InputLabel className="mt-2 mb-1" id="property-label">{p.name}</InputLabel>
                                            }

                                            {
                                                p.containerDefinition ?
                                                    <div>
                                                        {
                                                            (p.value as Object[]).map((container: any, i: number) => {
                                                                return (
                                                                    <>
                                                                        <div className="flex">
                                                                            <InputLabel className=" mt-2" id="property-label">Container {i + 1}</InputLabel>
                                                                            <IconButton onClick={
                                                                                () => {
                                                                                    let newContainerDefinitions = [...properties[index].value.map((v: any) => {
                                                                                        return { ...v }
                                                                                    })];
                                                                                    newContainerDefinitions.splice(i, 1)
                                                                                    setProperty(properties[index], newContainerDefinitions);
                                                                                }
                                                                            }>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                        <TextField
                                                                            id={`container-name-${index}-${i}`}
                                                                            label={"Image URI"}
                                                                            error={!p.validate!(data).isValid && messagesStartingWithIndex(p.validate!(data).messages, i).filter((m) => {
                                                                                return m.startsWith("Invalid image URI")
                                                                            }).length > 0}
                                                                            helperText={<span>{messagesStartingWithIndex(p.validate!(data).messages, i).filter((m) => {
                                                                                return m.startsWith("Invalid image URI")
                                                                            }).map((m: string) => {
                                                                                return <div>{m}</div>
                                                                            })}</span>}
                                                                            sx={{ width: "100%", marginTop: "10px" }}
                                                                            value={container.imageUri}
                                                                            onChange={(event) => handleContainerDefinitionChange(index, event, i, "imageUri")} />
                                                                        {
                                                                            container.ports.map((port: any, j: number) => {
                                                                                return (
                                                                                    <div className="flex gap-1 mt-[10px]">
                                                                                        <TextField
                                                                                            id={`container-port-${index}-${i}-${j}`}
                                                                                            error={!p.validate!(data).isValid && messagesStartingWithIndex(messagesStartingWithIndex(p.validate!(data).messages, i), j).filter((m) => {
                                                                                                return m.startsWith("Invalid container port")
                                                                                            }).length > 0}
                                                                                            helperText={<span>{messagesStartingWithIndex(messagesStartingWithIndex(p.validate!(data).messages, i), j).filter((m) => {
                                                                                                return m.startsWith("Invalid container port")
                                                                                            }).map((m: string) => {
                                                                                                return <div>{m}</div>
                                                                                            })}</span>}
                                                                                            label="Container Port"
                                                                                            value={port.containerPort}
                                                                                            onChange={(event) => handleContainerDefinitionChange(index, event, i, "containerPort", j)} />
                                                                                        <TextField
                                                                                            id={`host-port-${index}-${i}-${j}`}
                                                                                            error={!p.validate!(data).isValid && messagesStartingWithIndex(messagesStartingWithIndex(p.validate!(data).messages, i), j).filter((m) => {
                                                                                                return m.startsWith("Invalid host port")
                                                                                            }).length > 0}
                                                                                            helperText={<span>{messagesStartingWithIndex(messagesStartingWithIndex(p.validate!(data).messages, i), j).filter((m) => {
                                                                                                return m.startsWith("Invalid host port")
                                                                                            }).map((m: string) => {
                                                                                                return <div>{m}</div>
                                                                                            })}</span>}
                                                                                            label="Host Port"
                                                                                            value={port.hostPort}
                                                                                            onChange={(event) => handleContainerDefinitionChange(index, event, i, "hostPort", j)} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            (p.value as Object[]).length === i + 1 &&
                                                                            <Button
                                                                                onClick={() => addContainer(index)}
                                                                                sx={{
                                                                                    height: "56px",
                                                                                    minWidth: "130px",
                                                                                    padding: "5px 0",
                                                                                    marginTop: "10px",
                                                                                }} variant="outlined">Add Container</Button>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : p.isDockerImage ?
                                                        <div className="flex gap-1">
                                                            <TextField
                                                                error={p.validate ? !p.validate(data).isValid : false}
                                                                helperText={p.validate ?
                                                                    validateDockerImageNotAws(p.value as string).isValid ?
                                                                        "This image is not hosted on AWS Elastic Container Registry. You can upload it to ECR by clicking on the button." :
                                                                        <span>{p.validate(data).messages.map((m: string) => {
                                                                            return <div>{m}</div>
                                                                        })}</span> : false}
                                                                className="w-full"
                                                                variant="outlined"
                                                                type={typeof p.defaultValue}
                                                                value={p.value}
                                                                onChange={(e) => handlePropertyInputChange(e, index)}
                                                            />
                                                            {p.value && (p.value as string).length > 0 &&
                                                                validateDockerImageNotAws(p.value as string).isValid &&
                                                                !validateAwsImageUri(p.value as string).isValid &&
                                                                <Tooltip title={!isAccessKeyPresent ? "You need to set your AWS Access Key first. You can do that in Settings > Credentials" : false} placement="top">
                                                                    <div>
                                                                        <Button
                                                                            disabled={loadingDockerImageIndex === index || !isAccessKeyPresent}
                                                                            onClick={() => pushImage(p.value as string, index)}
                                                                            sx={{
                                                                                height: "56px",
                                                                                minWidth: "130px",
                                                                                padding: "5px 0",
                                                                            }} variant="outlined">{
                                                                                loadingDockerImageIndex === index ?
                                                                                    "Uploading..." : "Upload to ECR"
                                                                            }</Button>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        </div> :
                                                        p.multipleChoice === true && p.choices ?
                                                            <FormControl className="w-full">
                                                                <Select

                                                                    id="multiple-choice"
                                                                    multiple
                                                                    value={p.value as string[] || []}
                                                                    onChange={(e) => handleMultipleChoiceChange(e, index)}
                                                                >
                                                                    {p.choices.map((choice, i) => (
                                                                        <MenuItem key={i} value={choice}>
                                                                            {choice}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl> :
                                                            p.databaseKey === true ?
                                                                <Grid container className="mb-2" spacing={1}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            sx={{ width: "100%" }}
                                                                            error={p.validate ? !p.validate(data).isValid : false}
                                                                            helperText={p.validate ? <span>{p.validate(data).messages.map((m: string) => {
                                                                                return <div>{m}</div>
                                                                            })}</span> : false}
                                                                            label="Name" value={(p.value as any).name}
                                                                            onChange={(event) => handleDBKeyChange(index, event, "name")} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            error={p.validate ? !p.validate(data).isValid : false}
                                                                            value={(p.value as any).type}
                                                                            onChange={(event) => handleDBKeyChange(index, event, "type")}
                                                                            select // tell TextField to render select
                                                                            label="Type"
                                                                            sx={{ width: "100%" }}
                                                                        >
                                                                            <MenuItem key={1} value="string">
                                                                                string
                                                                            </MenuItem>
                                                                            <MenuItem key={2} value="number">
                                                                                number
                                                                            </MenuItem>
                                                                            <MenuItem key={3} value="binary">
                                                                                binary
                                                                            </MenuItem>
                                                                        </TextField>

                                                                    </Grid>
                                                                </Grid> :
                                                                p.databaseEntry === true ?
                                                                    (p.value as Object[]).map((dbEntry: any, i: number) => {
                                                                        return <Grid key={`db-form-${index}-${i}`} container className="mb-2" spacing={1}>
                                                                            <Grid item xs={8}>
                                                                                <TextField
                                                                                    sx={{ width: "100%" }}
                                                                                    error={!p.validate!(data).isValid && messagesStartingWithIndex(p.validate!(data).messages, i).length > 0}
                                                                                    helperText={<span>{messagesStartingWithIndex(p.validate!(data).messages, i).map((m: string) => {
                                                                                        return <div>{m}</div>
                                                                                    })}</span>}
                                                                                    label="Name" value={dbEntry.name}
                                                                                    onChange={(event) => handleDBEntryChange(index, event, i, "name")} />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    error={!p.validate!(data).isValid && messagesStartingWithIndex(p.validate!(data).messages, i).length > 0}
                                                                                    id={`type-select-${index}-${i}`}
                                                                                    value={dbEntry.type}
                                                                                    onChange={(event) => handleDBEntryChange(index, event, i, "type")}
                                                                                    select // tell TextField to render select
                                                                                    label="Type"
                                                                                    sx={{ width: "100%" }}
                                                                                >
                                                                                    <MenuItem key={1} value="string">
                                                                                        string
                                                                                    </MenuItem>
                                                                                    <MenuItem key={2} value="number">
                                                                                        number
                                                                                    </MenuItem>
                                                                                    <MenuItem key={3} value="binary">
                                                                                        binary
                                                                                    </MenuItem>
                                                                                </TextField>

                                                                            </Grid>
                                                                        </Grid>
                                                                    })
                                                                    : p.type === "Object" ?
                                                                        <>
                                                                            {[
                                                                                ...Object.entries(p.value).map(([name, value]) => ({ name, value })),
                                                                                { name: '', value: '' }
                                                                            ].map((line, j) => (
                                                                                <Grid container className="mb-2" spacing={1} key={`line-${j}`}>
                                                                                    <Grid item xs={4}>
                                                                                        <TextField className="w-full" value={line.name} id={`key-${j}`} label={`Key ${j + 1}`} name="key" onChange={event => handleChangeInput(index, j, event)} />
                                                                                    </Grid>
                                                                                    <Grid item xs={8}>
                                                                                        <TextField className="w-full" value={line.value} id={`value-${j}`} label={`Value ${j + 1}`} name="value" onChange={event => handleChangeInput(index, j, event)} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        p.choices && !p.multipleChoice ?
                                                                            <>
                                                                                <Select
                                                                                    className="w-full"
                                                                                    id="select-property"
                                                                                    error={p.validate ? !p.validate(data).isValid : false}

                                                                                    value={p.value}
                                                                                    onChange={(e) => handlePropertyInputChange(e, index)}
                                                                                >
                                                                                    {
                                                                                        p.choices.map((c) => {
                                                                                            return (
                                                                                                <MenuItem value={c}>{c}</MenuItem>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                                {
                                                                                    p.validate && !p.validate(data).isValid &&
                                                                                    <div className="text-red-700 text-sm">
                                                                                        {p.validate(data).messages.map((m: string) => {
                                                                                            return <div>{m}</div>
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                            </> :
                                                                            <TextField
                                                                                error={p.validate ? !p.validate(data).isValid : false}
                                                                                helperText={p.validate ? <span>{p.validate(data).messages.map((m: string) => {
                                                                                    return <div>{m}</div>
                                                                                })}</span> : false}
                                                                                className="w-full"
                                                                                variant="outlined"
                                                                                type={typeof p.defaultValue}
                                                                                value={p.value}
                                                                                onChange={(e) => handlePropertyInputChange(e, index)}
                                                                            />
                                            }
                                        </>
                                }
                            </>
                        )
                    }) :
                    <div className=" text-base text-[#666666]">
                        There is no property to set, you’re all good!
                    </div>
                }
            </>
        )
    }

    return (
        <div className="w-full"
        >
            <Accordion
                disableGutters
                defaultExpanded
                sx={{
                    "&.MuiAccordion-root": {
                        boxShadow: "none",
                    },
                    '&.Mui-expanded': {
                        boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                    }
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className=" text-xl">Properties</div>
                </AccordionSummary>
                <AccordionDetails>
                    {getPropertiesFromArray(properties.filter(p => { return !p.advanced }))}
                </AccordionDetails>
            </Accordion>
            {properties.filter(p => { return p.advanced }).length > 0 &&
                <Accordion
                    disableGutters
                    sx={{
                        "&.MuiAccordion-root": {
                            borderRadius: 0,
                            boxShadow: "none",
                        },
                        '&.Mui-expanded': {
                            boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                        }
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className=" text-xl">Advanced</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {getPropertiesFromArray(properties.filter(p => { return p.advanced }))}
                    </AccordionDetails>
                </Accordion>
            }
        </div >
    )
}

export default PropertiesForm