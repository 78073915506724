import { Editor, useActions, useValue } from "@tldraw/tldraw"
import { MappedService } from "./EditorHandler"
import { useCallback, useEffect, useRef, useState } from "react"
import { FirebaseApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { doc, getFirestore } from "firebase/firestore"
import { updateTemplates } from "../utils/firestoreOps"
import { throttle } from 'lodash';
import { ArrowData } from "../services/Service"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import { enqueueSnackbar } from "notistack"


interface FolderHandlerProps {
    editor: Editor | undefined
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>
    arrowsDataRef: React.MutableRefObject<ArrowData[]>
    setRefreshTemplatesTimestamp: (timestamp: number) => void
    app: FirebaseApp
}

const FolderHandler = ({
    editor,
    app,
    serviceDataMapRef,
    arrowsDataRef,
    setRefreshTemplatesTimestamp
}: FolderHandlerProps) => {

    const [oldFoldersIds, setOldFoldersIds] = useState<string[]>([])
    const actions = useActions()
    const exportAsPng = actions['exportAsPng']

    const shapesOverlaps = useValue("folders overlaps", () => {
        if (!editor) return [];
        const shapesIds = editor.getPageShapeIds(editor.currentPage)
        return Array.from(shapesIds).filter((shapeId) => {
            return editor.getShape(shapeId)?.type === "folder"
        }).map((folderId, i) => {
            const containerBounds = editor.getShapePageBounds(folderId)
            return {
                folder: folderId,
                folderName: (editor.getShape(folderId)?.props as any).name,
                width: containerBounds?.width!,
                height: containerBounds?.height!,
                children: Array.from(shapesIds).filter((innerShapeId) => {
                    const innerBounds = editor.getShapePageBounds(innerShapeId)
                    return innerShapeId !== folderId &&
                        editor.getShape(innerShapeId)?.type !== "folder" &&
                        containerBounds?.contains(innerBounds!)
                }).map((innerShapeId) => {
                    return {
                        id: innerShapeId,
                        relativePositionX: editor.getShape(innerShapeId)?.x! - editor.getShape(folderId)?.x!,
                        relativePositionY: editor.getShape(innerShapeId)?.y! - editor.getShape(folderId)?.y!,
                        ...(editor.getShape(innerShapeId)?.type === "arrow" ? {
                            start: (editor.getShape(innerShapeId)?.props as any).start,
                            end: (editor.getShape(innerShapeId)?.props as any).end,
                        } : {}),
                        ...(editor.getShape(innerShapeId)?.type === "wrapper" ? {
                            width: (editor.getShape(innerShapeId)?.props as any).w,
                            height: (editor.getShape(innerShapeId)?.props as any).h,
                        } : {})
                    }
                })
            }
        })
    }, [editor])
    const shapesOverlapsRef = useRef(shapesOverlaps)

    useEffect(() => {
        if (!editor) return;
        //check if there are new folders
        shapesOverlapsRef.current = shapesOverlaps
        const newFoldersIds = shapesOverlaps.map((folder) => folder.folder)
        const newFolders = newFoldersIds.filter((folderId) => !oldFoldersIds.includes(folderId))
        if (newFolders.length === 0) return;
        //for every new folder, update the shape
        newFolders.forEach((folderId) => {

            editor.updateShape({
                id: folderId, type: "folder", props: {
                    onClick: () => {
                        editor.setSelectedShapes([])
                        setTimeout(() => {
                            const folder = shapesOverlapsRef.current.filter((s) => {
                                return s.folder === folderId
                            })[0]
                            updateTemplates([folder], serviceDataMapRef.current, arrowsDataRef.current, app)
                            editor.getSvg([folder.folder, ...folder.children.map((c) => c.id)])
                                .then((svg) => {
                                    //save the svg to cloud storage
                                    const auth = getAuth(app)
                                    const storage = getStorage(app);
                                    const storageRef = ref(storage, "templates/" + auth.currentUser?.uid + "/" + folderId + ".svg");
                                    const svgData = new XMLSerializer().serializeToString(svg!)
                                    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" })
                                    uploadBytes(storageRef, svgBlob).catch((e) => console.error(e.message)).then(() => {
                                        setRefreshTemplatesTimestamp(Date.now())
                                        enqueueSnackbar("Template saved", { variant: "success" })
                                    });
                                })
                        }, 10)
                    }
                }
            })

        })
        setOldFoldersIds(newFoldersIds)
    }, [JSON.stringify(shapesOverlaps), editor]);

    return (<></>)
}

export default FolderHandler