import { Editor, react, TLShape, TLShapeId, TLShapeProp, useNativeClipboardEvents, useValue } from "@tldraw/tldraw"
import Sidebar from "../sidebar/Sidebar"
import { useEffect, useState, useRef } from "react"
import { ArrowData, arrowsProperties, ServiceData, services, ServiceTranslation } from "../services/Service"
import { ArrowProps } from "react-bootstrap/esm/Overlay"
import { CodeComponent } from "../code/codeWriteUtils"
import { FirebaseApp } from "firebase/app"
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import WrappersHandler from "./WrappersHandler"
import ArrowsHandler from "./ArrowsHandler"
import { getServiceDataMap, updateArrowsData, updateServiceDataMap } from "../utils/firestoreOps"
import { getAvailableServiceName } from "../utils/naming"
import SelectionHandler from "./SelectionHandler"
import ValidationHandler from "./ValidationHandler"
import { Folder } from "@mui/icons-material"
import FolderHandler from "./FolderHandler"
import { getShapesDataCopy } from "../utils/shapes"

interface EditorHandlerProps {
    sidebarWidth: string
    editor: Editor | undefined
    setIsValid: (value: boolean) => void
    app: FirebaseApp
}

export type MappedService = {
    serviceData: ServiceData,
    codeState: CodeComponent[],
    oldProperties: {
        name: string,
        value: string
    }[]
}

type ArrowBinding = {
    id: string
    startId: string
    endId: string
}

const EditorHandler = ({
    sidebarWidth, editor, app, setIsValid
}: EditorHandlerProps) => {

    const [serviceDataMap, setServiceDataMap] = useState<Map<string, MappedService>>(new Map<string, MappedService>)
    const [refreshTemplatesTimestamp, setRefreshTemplatesTimestamp] = useState<number>(0)
    const serviceDataMapRef = useRef(serviceDataMap)
    const [arrowsData, setArrowsData] = useState<ArrowData[]>([])
    const arrowsDataRef = useRef(arrowsData)
    const copiedShapesIdsRef = useRef<string[]>([])
    const copiedShapes = useRef<TLShape[]>([])
    const copiedServiceDataMapRef = useRef<Map<string, MappedService>>()
    const copiedArrowsDataRef = useRef<ArrowData[]>([])
    const [isDataInitialized, setIsDataInitialized] = useState(false)
    const shapeIds = [...useValue("shapes ids", () => {
        if (!editor) return [];
        return Array.from(editor!.getPageShapeIds(editor!.currentPage))
    }, [editor])];
    const editorState = useValue("editor state", () => {
        if (!editor) return "";
        return editor.root.path.value
    }, [editor]);
    const selectedShapeId = useValue("shape selection", () => {
        if (!editor || !isDataInitialized) return "";
        const selectedShapes = editor.selectedShapeIds
        if (selectedShapes.length === 1) {
            return selectedShapes[0]
        }
        else return ""
    }, [editor, isDataInitialized])

    const fs = getFirestore(app)
    const auth = getAuth(app)

    const clearCopiedData = () => {
        copiedShapesIdsRef.current = []
        copiedShapes.current = []
        copiedServiceDataMapRef.current = undefined
        copiedArrowsDataRef.current = []
    }

    const setEditorBackground = (theme: "light" | "dark") => {
        const elm = document.getElementsByClassName('tl-background')[0] as HTMLElement
        if (elm) {
            theme === "dark" ? elm.style.setProperty('background-color', '#a2a2a2') : elm.style.removeProperty("background-color")
        }
    }

    useEffect(() => {
        if (!editor) return;

        editor.setSelectedShapes([])

        editor.sideEffects.registerAfterChangeHandler("shape", (s) => {
            if (s.type === "arrow" && (s.props as any).bend !== 0) {
                editor.setCurrentTool("arrow")
                editor.setCurrentTool("select")
                editor.updateShape({
                    id: s.id,
                    type: "arrow",
                    props: {
                        bend: 0
                    }
                })
            }
        })
        editor.sideEffects.registerBeforeChangeHandler("shape", (_prev, record) => {
            if (record.type === "arrow" && (record.props as any).start.type !== "binding" && (record.props as any).end.type !== "binding") {
                return _prev
            }
            return record
        })

        function handleCopy() {
            if (editor && editor.instanceState.isFocused) {
                copiedShapesIdsRef.current = editor.selectedShapeIds
                copiedShapes.current = JSON.parse(JSON.stringify(editor.selectedShapes))
                copiedServiceDataMapRef.current = deepCloneServiceDataMap(serviceDataMapRef.current)
                copiedArrowsDataRef.current = deepCloneArrowsData(arrowsDataRef.current)
            } else {
                clearCopiedData()
            }
        }

        async function handlePaste() {
            if (editor && editor.instanceState.isFocused && copiedShapesIdsRef.current.length > 0) {
                pasteShapes(copiedShapesIdsRef.current)
            }
        }


        document.addEventListener('visibilitychange', clearCopiedData);

        window.addEventListener('blur', () => clearCopiedData);
        window.addEventListener('focus', () => clearCopiedData);

        document.addEventListener('copy', handleCopy);
        document.addEventListener('paste', handlePaste);
        document.addEventListener('cut', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('cut', handleCopy);
            document.removeEventListener('paste', handlePaste);
            document.removeEventListener('visibilitychange', clearCopiedData);
            window.removeEventListener('blur', () => clearCopiedData);
            window.removeEventListener('focus', () => clearCopiedData);
        };

    }, [editor])

    const deepCloneArrowsData = (ad: ArrowData[]) => {
        return [...ad.map((a) => {
            return deepCloneArrow(a)
        })]
    }

    const deepCloneServiceDataMap = (sdm: Map<string, MappedService>) => {
        // Some properties are not cloned, but emptied out
        const newServiceDataMap = new Map<string, MappedService>
        sdm.forEach((mappedService, key) => {
            const newS: MappedService = {
                codeState: JSON.parse(JSON.stringify(mappedService?.codeState)),
                serviceData: JSON.parse(JSON.stringify({
                    ...mappedService.serviceData,
                    properties: mappedService.serviceData.properties.map((p) => {
                        return {
                            ...p,
                            condition: undefined,
                            validate: undefined
                        }
                    }),
                    arrowIn: [],
                    validate: undefined,
                    arrowOut: [],
                    parents: []
                })),
                oldProperties: []
            }
            newS.serviceData.validate = mappedService.serviceData.validate
            newS.serviceData.properties.forEach((p, index) => {
                p.condition = mappedService.serviceData.properties[index].condition
                p.validate = mappedService.serviceData.properties[index].validate
            })
            newServiceDataMap.set(key, newS)
        })
        return newServiceDataMap;
    }

    const deepCloneArrow = (arrow: ArrowData) => {
        const newArrow: ArrowData = JSON.parse(JSON.stringify({
            ...arrow,
            properties: arrow.properties.map((p) => {
                return {
                    ...p,
                    condition: undefined
                }
            })
        }))
        newArrow.properties.forEach((p, index) => {
            p.condition = arrow.properties[index].condition
        })
        return newArrow
    }

    const pasteShapes = (shapeIds: string[]) => {
        const { newServices, newArrows } = getShapesDataCopy(shapeIds,
            copiedServiceDataMapRef.current && deepCloneServiceDataMap(copiedServiceDataMapRef.current),
            deepCloneArrowsData(copiedArrowsDataRef.current),
            serviceDataMapRef.current,
        )
        const shapeOffset = 50
        newServices.forEach(({ newId, oldId, mappedService }) => {
            serviceDataMapRef.current.set(newId, mappedService)
            const oldShape = copiedShapes.current.filter((s) => { return s.id === oldId })[0]
            editor?.createShape({
                ...oldShape,
                props: {
                    ...oldShape.props,
                    name: mappedService.serviceData.name
                },
                x: oldShape.x + shapeOffset,
                y: oldShape.y + shapeOffset,
                id: newId as TLShapeId
            })
        })
        refreshServiceDataMap()
        newArrows.forEach(({ newId, oldId, arrowData }) => {
            arrowsDataRef.current.push(arrowData)
            const oldShape = copiedShapes.current.filter((s) => { return s.id === oldId })[0]
            editor?.createShape({
                ...oldShape,
                x: oldShape.x + shapeOffset,
                y: oldShape.y + shapeOffset,
                props: {
                    ...oldShape.props,
                    start: {
                        ...(oldShape.props as any).start,
                        boundShapeId: arrowData.startId
                    },
                    end: {
                        ...(oldShape.props as any).end,
                        boundShapeId: arrowData.endId
                    }
                },
                id: newId as TLShapeId
            })
        })
        setTimeout(() => {
            editor?.setSelectedShapes([...newServices.map(({ newId }) => { return newId as TLShapeId }), ...newArrows.map(({ newId }) => { return newId as TLShapeId })])
        }, 1)
    }


    useEffect(() => {
        if (isDataInitialized) return;
        const getExistingServiceDataMap = async () => {
            const sdm = await getServiceDataMap(app)
            if (sdm) {
                setServiceDataMap(sdm.serviceDataMap)
                setArrowsData(sdm.arrowsData)
                arrowsDataRef.current = sdm.arrowsData
                serviceDataMapRef.current = sdm.serviceDataMap
            }
            setIsDataInitialized(true)
        }
        getExistingServiceDataMap()
    }, [isDataInitialized])

    const setFsArrowsData = async (newArrows: ArrowData[]) => {
        if (!isDataInitialized) return;
        await updateArrowsData(newArrows, app)
    }

    const setFsServiceDataMap = async (dataMap: Map<string, MappedService>) => {
        if (!isDataInitialized) return;
        await updateServiceDataMap(dataMap, app)
    }

    useEffect(() => {
        if (!editor) return;
        serviceDataMap.forEach((s, id) => {
            const allShapes = editor!.getPageShapeIds(editor!.currentPage)
            if (!allShapes.has(id as TLShapeId)) {
                serviceDataMap.delete(id)
            }
        })
        setFsServiceDataMap(serviceDataMap)
    }, [JSON.stringify(shapeIds)])

    useEffect(() => {
        if (!editor) return
        return react("clear history", () => {
            editor.getPageShapeIds(editor.currentPage)
            editor.history.clear()
        })
    }, [editor])

    useEffect(() => {
        if (!isDataInitialized) return
        const shapesToDelete: TLShape[] = []
        shapeIds.forEach((id) => {
            const shape = editor?.getShape(id)!
            if (shape) {
                if (["card", "wrapper", "arrow", "folder"].indexOf(shape.type) === -1 ||
                    (["card", "wrapper"].indexOf(shape.type) !== -1 && !serviceDataMap.get(id)) ||
                    (shape.type === "arrow" && (shape?.props as any).start.type === "binding" && (shape?.props as any).end.type === "binding" &&
                        (shape?.props as any).start.boundShapeId !== (shape?.props as any).end.boundShapeId &&
                        !arrowsData.some((a) => { return a.shapeId === id }))) {
                    shapesToDelete.push(shape)
                } else {
                    if (shape.type === "wrapper") {
                        editor?.sendToBack([shape])
                    }

                    if (shape.type === "arrow" && ((shape?.props as any).start.type !== "binding" || (shape?.props as any).end.type !== "binding")) {
                        shapesToDelete.push(shape)
                    }
                }
            }
        })
        editor?.deleteShapes(shapesToDelete)
        shapeIds.forEach((id) => {
            const shape = editor?.getShape(id)!
            if (shape && shape.type === "folder") {
                editor?.sendToBack([shape])
            }
        })
    }, [JSON.stringify(shapeIds)])


    const refreshServiceDataMap = () => {
        setServiceDataMap((prev: Map<string, MappedService>) => {
            setFsServiceDataMap(prev)
            serviceDataMapRef.current = new Map(prev);
            return serviceDataMapRef.current
        })
    }

    const refreshArrowsData = () => {
        const newArrowData = [...arrowsDataRef.current]
        setFsArrowsData(newArrowData)
        arrowsDataRef.current = newArrowData
        setArrowsData(newArrowData)
    }

    return (
        <>
            <SelectionHandler setEditorBackground={setEditorBackground} isDataInitialized={isDataInitialized} shapeIds={shapeIds} editorState={editorState} editor={editor} serviceDataMap={serviceDataMap} />
            <ValidationHandler setIsValid={setIsValid} editor={editor} serviceDataMap={serviceDataMap} />
            <FolderHandler app={app} editor={editor} serviceDataMapRef={serviceDataMapRef} arrowsDataRef={arrowsDataRef} setRefreshTemplatesTimestamp={setRefreshTemplatesTimestamp} />
            <WrappersHandler editor={editor}
                editorState={editorState}
                shapeIds={shapeIds}
                refreshServiceDataMap={refreshServiceDataMap}
                serviceDataMapRef={serviceDataMapRef} />
            <ArrowsHandler
                editor={editor}
                setEditorBackground={setEditorBackground}
                editorState={editorState}
                serviceDataMapRef={serviceDataMapRef}
                selectedShapeId={selectedShapeId} arrowsData={arrowsData}
                setArrowsData={setArrowsData}
                arrowsDataRef={arrowsDataRef}
                setFsArrowsData={setFsArrowsData}
                setFsServiceDataMap={setFsServiceDataMap}
                refreshServiceDataMap={refreshServiceDataMap} />
            <Sidebar
                app={app}
                refreshTemplatesTimestamp={refreshTemplatesTimestamp}
                width={sidebarWidth}
                setFsServiceDataMap={setFsServiceDataMap}
                arrowsData={arrowsData}
                refreshArrowsData={refreshArrowsData}
                editor={editor}
                selectedShapeId={selectedShapeId}
                serviceDataMap={serviceDataMap}
                refreshServiceDataMap={refreshServiceDataMap} />
        </>
    )
}
export default EditorHandler