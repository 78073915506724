import { MappedService } from "../editor-handler/EditorHandler";

export const getAvailableName = (desiredName: string, existingNames: string[]) => {
    if (!existingNames.includes(desiredName)) {
        return desiredName;
    }

    let i = 1;

    while (true) {
        let possibleName = `${desiredName}-${i}`;
        if (!existingNames.includes(possibleName)) {
            return possibleName;
        }
        i++;
    }
}

export const getAvailableServiceName = (desiredName: string, serviceDataMap: Map<string, MappedService>, excludeCurrentShape?: boolean, selectedShapeId?: string) => {
    const existingNames = Array.from(serviceDataMap.entries()).filter(([key, value]) => {
        return excludeCurrentShape ? key !== selectedShapeId : true
    }).map(([key, value]) => {
        return value.serviceData.name
    })
    return getAvailableName(desiredName, existingNames)
}