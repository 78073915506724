interface ElementNameProps {
    name: string
    subtitle: string
    error: boolean
    errorMessages: string[]
}
const ElementName = ({ name, subtitle, error, errorMessages }: ElementNameProps) => {
    return (
        <div className="top-5 pl-3 pt-5 pb-5 max-w-[20rem]">
            <div className={"mb-1 max-w-full text-2xl truncate" + (error ? " text-red-700" : "")}>
                {name}
            </div>
            {
                errorMessages.map((m) => {
                    return <div className="max-w-full text-xs text-red-700">
                        {m}
                    </div>
                })
            }
            <div className="max-w-full text-xs truncate text-[#666666]">
                {subtitle}
            </div>
        </div>
    )
}

export default ElementName