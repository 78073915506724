import { Editor, TLShapeId, react, useValue } from "@tldraw/tldraw";
import { useEffect, useRef, useState } from "react"
import { MappedService } from "./EditorHandler";
import { ServiceData } from "../services/Service";

interface WrappersHandlerProps {
    editor: Editor | undefined
    editorState: string
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>
    refreshServiceDataMap: () => void
    shapeIds: string[]
}

const WrappersHandler = ({
    editorState,
    refreshServiceDataMap,
    serviceDataMapRef,
    editor,
    shapeIds
}: WrappersHandlerProps) => {

    const [refreshDataMap, setRefreshDataMap] = useState(0)
    const [shapesChanged, setShapesChanged] = useState(false)

    const shapesOverlaps = useValue("shapes overlaps", () => {
        if (!editor) return [];
        const shapesIds = editor.getPageShapeIds(editor.currentPage)
        return Array.from(shapesIds).filter((shapeId) => {
            return serviceDataMapRef.current.get(shapeId)?.serviceData.isWrapper
        }).map((shapeId, i) => {
            const containerBounds = editor.getShapePageBounds(shapeId)
            return {
                parent: shapeId,
                children: Array.from(shapesIds).filter((innerShapeId) => {
                    const innerBounds = editor.getShapePageBounds(innerShapeId)
                    return innerShapeId !== shapeId && containerBounds?.contains(innerBounds!)
                })
            }
        })
    }, [editor])

    useEffect(() => {
        setShapesChanged(true)
    }, [JSON.stringify(shapesOverlaps)])

    useEffect(() => {
        if (!editor || editorState === "" || editor.isIn("arrow") || (!editor.isIn("select.translating") && !shapesChanged)) return;
        setShapesChanged(false)
        const shapesIds = Array.from(editor.getPageShapeIds(editor.currentPage)).filter((id) => {
            return editor.getShape(id)?.type !== "arrow"
        })
        removeAllHighlights()
        if (editor.selectedShapeIds.length === 1 && editor.getShape(editor.selectedShapeIds[0])?.type === "folder") {
            return;
        }
        if (editor.isIn("select.translating") && editor?.selectedShapeIds) {
            setWrappersTransparency(editor.selectedShapeIds)
            highlightAllParentInputs(editor.selectedShapeIds)
        }
        shapesIds.filter((shapeId) => {
            return serviceDataMapRef.current.get(shapeId)?.serviceData.isWrapper
        }).forEach((shapeId, i) => {
            const containerBounds = editor.getShapePageBounds(shapeId)
            shapesIds.filter((innerShapeId) => {
                return innerShapeId !== shapeId
            }).forEach((innerShapeId, j) => {
                const innerBounds = editor.getShapePageBounds(innerShapeId)
                if (containerBounds?.contains(innerBounds!)) {
                    if (areTypesCompatible(innerShapeId, shapeId) && editor.selectedShapeIds.indexOf(innerShapeId) !== -1) {
                        editor.bringToFront([innerShapeId as TLShapeId])
                        setWrapperContains(shapeId, innerShapeId)
                        if (editor.selectedShapeIds.indexOf(innerShapeId) !== -1)
                            setHighlightedProperties(innerShapeId, serviceDataMapRef.current.get(shapeId)?.serviceData.serviceName!)
                    }
                }
            })
        })
        serviceDataMapRef.current.forEach((value, id) => {
            const innerBounds = editor.getShapePageBounds(id as TLShapeId)
            if (innerBounds) {
                const parents = value.serviceData.parents
                const newParents: {
                    serviceData: ServiceData,
                    shapeId: string
                }[] = []
                parents.forEach((p) => {
                    const wrapperBounds = editor.getShapePageBounds(p.shapeId as TLShapeId)
                    if (wrapperBounds?.contains(innerBounds!)) {
                        newParents.push(p)
                    }
                })
                if (parents.some((p) => {
                    return !newParents.some((np) => {
                        return p.serviceData === np.serviceData && p.shapeId === np.shapeId
                    })
                })) {
                    value.serviceData.parents = newParents
                    setRefreshDataMap(Date.now())
                }
            }
        })
    }, [editorState, shapesChanged, JSON.stringify(shapesOverlaps)]);


    const highlightAllParentInputs = (selectedShapeIds: string[]) => {
        selectedShapeIds.forEach((id) => {
            const mappedService = serviceDataMapRef.current.get(id)
            if (mappedService) {
                const parentsTypes = mappedService.serviceData.parentsTypes
                editor?.updateShape({
                    id: id as TLShapeId,
                    type: mappedService.serviceData.isWrapper ? "wrapper" : "card",
                    props: {
                        inputs: [...parentsTypes],
                        outputs: [],
                        transparentInputs: [],
                        transparentOutputs: [],
                    }
                })
            }
        })
    }


    const setHighlightedProperties = (shapeId: string, serviceName: string) => {
        const nonCommonTypes = (editor?.getShape(shapeId as TLShapeId)?.props as any).inputs.filter((t: string) => {
            return t !== serviceName
        })
        editor?.updateShape({
            id: shapeId as TLShapeId, type: serviceDataMapRef.current.get(shapeId)?.serviceData.isWrapper ? "wrapper" : "card", props: {
                transparentInputs: nonCommonTypes
            }
        })
    }

    useEffect(() => {
        refreshServiceDataMap()
    }, [refreshDataMap])

    const areTypesCompatible = (childId: string, parentId: string) => {
        const parent = serviceDataMapRef.current.get(parentId)?.serviceData
        return parent && serviceDataMapRef.current.get(childId)?.serviceData.parentsTypes.indexOf(parent.serviceName) !== -1
    }

    const setWrappersTransparency = (ids: string[]) => {
        const nonTransparentShapesIds = [...ids]
        ids.forEach((id) => {
            serviceDataMapRef.current.forEach((value, key) => {
                if (key !== id) {
                    if (value.serviceData.isWrapper && areTypesCompatible(id, key) && nonTransparentShapesIds.indexOf(key) === -1) {
                        nonTransparentShapesIds.push(key)
                    }
                }
            })
        })
        const transparentShapes = Array.from(editor?.getPageShapeIds(editor.currentPage) || []).filter((shapeId) => {
            return nonTransparentShapesIds.indexOf(shapeId) === -1 && editor?.getShape(shapeId)?.type !== "folder"
        })
        transparentShapes.forEach((tId) => {
            editor?.updateShape({ id: tId, type: editor.getShape(tId)!.type, opacity: 0.2 })
        })
    }

    const removeAllHighlights = () => {
        const shapesIds = editor?.getPageShapeIds(editor.currentPage)
        shapesIds && shapesIds.forEach((id) => {
            const type = editor?.getShape(id)?.type
            editor?.updateShape({ id: id, type: type!, opacity: 1 })
        })
    }

    const setWrapperContains = (wrapperId: string, innerShapeId: string) => {
        const parentData = serviceDataMapRef.current.get(wrapperId)?.serviceData!
        const parents = serviceDataMapRef.current.get(innerShapeId)?.serviceData.parents
        if (!parents?.some((p) => {
            return p.serviceData === parentData
        })) {
            parents?.push({
                serviceData: parentData,
                shapeId: wrapperId
            })
            setRefreshDataMap(Date.now())
        }
    }

    return (<></>)

}
export default WrappersHandler