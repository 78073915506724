import { Editor, TLShape, TLShapeId } from "@tldraw/tldraw"
import { MappedService } from "./EditorHandler"
import { useEffect } from "react"

interface ValidationHandlerProps {
    editor: Editor | undefined
    serviceDataMap: Map<string, MappedService>
    setIsValid: (value: boolean) => void
}

const ValidationHandler = ({ editor, serviceDataMap, setIsValid }: ValidationHandlerProps) => {

    useEffect(() => {
        if (!editor || serviceDataMap.size === 0) {
            setIsValid(true)
            return;
        }
        let isGloballyValid = true
        serviceDataMap.forEach((mappedService, key) => {
            const serviceData = mappedService.serviceData
            const { isValid, messages } = serviceData.validate(serviceData)
            if (!isValid) isGloballyValid = false
            editor.updateShape({
                id: key as TLShapeId, type: editor.getShape(key as TLShapeId)?.type!, props: {
                    isValid: isValid,
                    messages: messages
                }
            })
        })
        setIsValid(isGloballyValid)
    }, [editor, JSON.stringify(Array.from(serviceDataMap.entries()).map((value) => {
        return [value[0],
        {
            ...value[1].serviceData,
            arrowIn: value[1].serviceData.arrowIn.map((a) => {
                return a.arrowData
            }),
            arrowOut: value[1].serviceData.arrowOut.map((a) => {
                return a.arrowData
            }),
            parents: value[1].serviceData.parents.map((p) => {
                return p.shapeId
            })
        }
        ]
    }))])

    return (<></>)
}
export default ValidationHandler