import { Check, Close, Edit } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import ElementName from "./ElementName";
import { ServiceData } from "../services/Service";

interface ServiceNameFormProps {
    serviceType: string,
    serviceName: string,
    setServiceName: (name: string) => void
    serviceData?: ServiceData
}

const ServiceNameForm = ({ serviceName, setServiceName, serviceType, serviceData }: ServiceNameFormProps) => {
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState<string>(serviceName);
    const inputRef = useRef<any>(null)

    const nameProp = serviceData?.properties.filter((p) => {
        return p.name === "Name"
    })[0]
    const { isValid, messages } = nameProp && nameProp.validate ? nameProp.validate(serviceData) : { isValid: true, messages: [] }

    const resetName = () => {
        setServiceName(serviceName);
    };

    const onNameFormBlur = () => {
        if (editMode) {
            setServiceName(value);
            setEditMode(false);
        }
    }

    return (
        <>
            <div className="flex max-w-[25rem]"
                onBlur={() => onNameFormBlur()}
            >
                {
                    editMode ?
                        <div className="top-5 flex pl-3 pt-5 pb-5">
                            <TextField
                                value={value}
                                variant={"standard"}
                                name="Meeting name"
                                inputRef={inputRef}
                                margin={"none"}
                                error={value === ""}
                                sx={{
                                    marginTop: 0,
                                    fontSize: "24px",
                                    fontWeight: "700",
                                    color: "#43475F",
                                    fontFamily: "inherit",
                                    padding: 0,
                                    maxWidth: "25rem"
                                }}
                                onChange={(e) => setValue(e.target.value)}
                                InputProps={{
                                    style: {
                                        fontSize: "1.5rem"/* 24px */,
                                        lineHeight: "2rem"/* 32px */,
                                        marginTop: 0,
                                        fontFamily: "inherit",
                                        padding: 0,
                                        width: "100%"
                                    },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            {editMode ?
                                                <>
                                                    <Tooltip title={"Apply"}>
                                                        <IconButton
                                                            disabled={!value}
                                                            onClick={() => {
                                                                setServiceName(value);
                                                                setEditMode(false);
                                                            }
                                                            }>
                                                            <Check />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Cancel"}>
                                                        <IconButton
                                                            onClick={() => {
                                                                resetName();
                                                                setValue(serviceName)
                                                                setEditMode(false);
                                                            }
                                                            }>
                                                            <Close />
                                                        </IconButton>
                                                    </Tooltip>
                                                </> :
                                                <Tooltip title={"Edit"}>
                                                    <IconButton
                                                        onClick={() =>
                                                            setEditMode(true)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </InputAdornment>,
                                }}
                            />
                        </div> :
                        <div onDoubleClick={() => {
                            setEditMode(true)
                            setTimeout(() => inputRef.current!.select(), 1)
                        }}                        >
                            <ElementName error={!isValid} errorMessages={messages} name={serviceName} subtitle={serviceType} />
                        </div>
                }
                {serviceName &&
                    <>
                        <div className="top-5 pt-5 pb-5">
                            {!editMode &&
                                <Tooltip title={"Edit name"}>
                                    <IconButton
                                        style={{
                                            width: "44px",
                                            height: "44px",
                                            marginTop: "-1vh"
                                        }}
                                        onClick={() => {
                                            setEditMode(true)
                                            setTimeout(() => inputRef.current!.select(), 1)
                                        }}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>

                    </>

                }
            </div>
        </>
    )
}
export default ServiceNameForm