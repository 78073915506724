import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText } from "@mui/material";
import { Service, services } from "../services/Service";
import { useEffect, useState } from "react";
import { getAvailableServiceName } from "../utils/naming";
import { TLShape, TLShapeId } from "@tldraw/tldraw";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'


interface ServicesListProps {
    editor: any
    serviceDataMap: Map<string, any>
    setFsServiceDataMap: (serviceDataMap: Map<string, any>) => void
    searchTerm: string
}

const ServicesList = (
    {
        editor,
        serviceDataMap,
        setFsServiceDataMap,
        searchTerm
    }: ServicesListProps
) => {

    const [images, setImages] = useState<HTMLImageElement[]>([]);
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        if (!services) return;
        //set images
        let imgs: HTMLImageElement[] = []
        services.forEach((s) => {
            const image = new Image();
            image.src = s.imgUrl;
            imgs.push(image);
        })
        setImages(imgs)
        //set unique categories
        const categories = services.map((s) => {
            return s.category
        })
        setCategories(Array.from(new Set(categories)))
    }, [services])

    const getList = (category?: string) => {
        return (
            <List>
                {
                    services.filter((s) => {
                        if (!category) return s.serviceName.toLowerCase().startsWith(searchTerm.toLowerCase());
                        return s.category === category

                    }).map((s, index: number) => (
                        <ListItem
                            className="w-full border-b cursor-grab"
                            sx={{
                                borderBottom: "none",
                            }}
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, services.indexOf(s))}
                            onDragEnd={(e) => handleDragEnd(e, s)}
                        >
                            <ListItemText
                                primary={s.serviceName}
                            />
                            <img className="w-8 h-8" src={s.imgUrl} alt={s.serviceName} />
                        </ListItem>
                    ))
                }
            </List>
        )
    }


    const handleDragStart = async (e: any, index: number) => {
        e.dataTransfer.setDragImage(images[index], 0, 0);
    }

    const handleDragEnd = (e: any, service: Service) => {
        if (!editor) return;
        const xPosition = e.clientX; // get the x position
        const windowWidth = window.innerWidth; // get the width of the window

        // compute the distance from the right
        const distanceFromRight = windowWidth - xPosition;
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        // convert pixels to rem
        const distanceFromRightRem = distanceFromRight / rootFontSize;
        if (distanceFromRightRem < 25) return;
        const serviceName = getAvailableServiceName(service.defaultName, serviceDataMap);
        const point = editor.screenToPage({ x: e.clientX, y: e.clientY });
        const shape = {
            id: "shape:" + service.serviceName + Date.now() as TLShapeId,
            type: service.isWrapper ? "wrapper" : "card",
            x: point.x,
            y: point.y,
            props: {
                borderColor: service.borderColor,
                backgroundColor: service.backgroundColor,
                name: serviceName,
                inputs: [],
                outputs: [],
            }
        }
        editor.createShape(shape);
        editor.setSelectedShapes([shape as TLShape])
        serviceDataMap.set(shape.id as string, {
            serviceData: {
                serviceName: service.serviceName,
                name: serviceName,
                filePath: service.filePath,
                validate: service.validate,
                isWrapper: service.isWrapper,
                properties: service.properties.map((p) => {
                    return {
                        name: p.name,
                        type: p.type,
                        isName: p.isName,
                        advanced: p.advanced,
                        isDockerImage: p.isDockerImage,
                        containerDefinition: p.containerDefinition,
                        validate: p.validate,
                        databaseEntry: p.databaseEntry,
                        databaseKey: p.databaseKey,
                        condition: p.condition,
                        value: p.isName ? serviceName : p.defaultValue,
                        defaultValue: p.defaultValue,
                        multipleChoice: p.multipleChoice,
                        choices: p.choices,
                        mandatory: p.mandatory,
                        hide: p.hide
                    }
                }),
                arrowInTypes: service.arrowInTypes,
                arrowOutTypes: service.arrowOutTypes,
                arrowIn: [],
                arrowOut: [],
                parents: [],
                parentsTypes: service.parentsTypes
            },
            codeState: [],
            oldProperties: []
        })
        setFsServiceDataMap(serviceDataMap)
    }

    return (
        <>
            {
                searchTerm === "" ?
                    categories.map((c, index: number) => (
                        <Accordion
                            disableGutters
                            defaultExpanded={index === 0}
                            sx={{
                                "&.MuiAccordion-root": {
                                    borderRadius: 0,
                                    boxShadow: "none",
                                },
                                '&.Mui-expanded': {
                                    boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                                }
                            }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className=" text-xl">{c}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getList(c)}
                            </AccordionDetails>
                        </Accordion>
                    )) : <Accordion
                        disableGutters
                        defaultExpanded
                        sx={{
                            "&.MuiAccordion-root": {
                                borderRadius: 0,
                                boxShadow: "none",
                            },
                            '&.Mui-expanded': {
                                boxShadow: "0 -1px 0 rgba(0, 0, 0, .125)"
                            }
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className=" text-xl">Services</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {getList()}
                        </AccordionDetails>
                    </Accordion>
            }
        </>
    )
}
export default ServicesList