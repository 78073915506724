import Editor from '@monaco-editor/react';
import { editor, KeyCode, KeyMod } from "monaco-editor"
import { useState, useEffect, useRef } from "react";

interface CodeBoxProps {
    codeText: string
    editorValue: string
    setEditorValue: (value: string) => void
    setIsEditorFocused: (value: boolean) => void
}

const CodeBox = ({
    codeText,
    editorValue,
    setEditorValue,
    setIsEditorFocused
}: CodeBoxProps) => {

    const [editor, setEditor] = useState<editor.IStandaloneCodeEditor>()

    useEffect(() => {
        if (!codeText) return;
        setEditorValue(codeText);
    }, [codeText])

    useEffect(() => {
        if (!editor) return;
        editor.onDidFocusEditorWidget(() => {
            setIsEditorFocused(true)
        })
        editor.onDidBlurEditorWidget(() => {
            setIsEditorFocused(false)
        })
    }, [editor])

    const handleEditorMounted = (e: editor.IStandaloneCodeEditor) => {
        setEditor(e)
        e.addCommand(KeyMod.CtrlCmd | KeyCode.KeyZ, function () { });
    }

    return (
        <Editor
            theme="vs-dark"
            options={{
                lineNumbers: "off",
                minimap: { enabled: false },
                autoIndent: "full",
                formatOnPaste: true,
                formatOnType: true,
            }}
            onMount={(e) => handleEditorMounted(e)}
            height="30vh" defaultLanguage="hcl" value={editorValue} onChange={(v) => { setEditorValue(v!) }} />
    )
}

export default CodeBox