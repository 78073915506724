import { TLShapeId } from "@tldraw/tldraw"
import { MappedService } from "../editor-handler/EditorHandler"
import { ArrowData } from "../services/Service"
import { getAvailableServiceName } from "./naming"

export const getShapesDataCopy = (shapeIds: string[], serviceToCopy: Map<string, MappedService> | undefined, arrowsToCopy: ArrowData[], serviceDataMap: Map<string, MappedService>) => {
    const newServices: { oldId: string, newId: string, mappedService: MappedService }[] = []
    const newArrows: { oldId: string, newId: string, arrowData: ArrowData }[] = []
    shapeIds.forEach((id) => {
        const mappedService = serviceToCopy && serviceToCopy.get(id)
        if (mappedService) {
            // It is a resource
            const newName = getAvailableServiceName(mappedService.serviceData.name, serviceDataMap)
            mappedService.codeState.forEach(c => {
                const regex = new RegExp("^(.*_)*(" + mappedService.serviceData.name + ")(_.*)*$", "gm")
                const hasMatch = (c.componentName.match(regex) || []).length > 0
                if (hasMatch) {
                    c.componentName = c.componentName.replace(regex, "$1" + newName + "$3")
                }
            })

            const nameProp = mappedService.serviceData.properties.filter((p) => {
                return p.isName === true
            });
            if (nameProp && nameProp?.length > 0) {
                nameProp[0].value = newName
            }
            mappedService.serviceData.name = newName
            newServices.push({ oldId: id, newId: "shape:" + mappedService.serviceData.serviceName + Math.random() * 10000000000000, mappedService: mappedService })
        } else {
            // It is an arrow
            const newArrow = arrowsToCopy.filter((a) => {
                return a.shapeId === id
            })[0]
            newArrow.shapeId = "shape:" + Math.random() * 10000000000000
            newArrows.push({ oldId: id, newId: newArrow.shapeId, arrowData: newArrow })
        }
    })
    const newArrowsFinal = newArrows.filter((a) => {
        return shapeIds.indexOf(a.arrowData.startId) !== -1 && shapeIds.indexOf(a.arrowData.endId) !== -1
    })
    newServices.forEach(({ oldId, newId, mappedService }) => {
        newArrowsFinal.filter((a) => {
            return a.arrowData.startId === oldId
        }).forEach((a) => {
            a.arrowData.startId = newId
            mappedService.serviceData.arrowOut.push({
                arrowData: a.arrowData, serviceData: newServices.filter(({ oldId, newId }) => {
                    return oldId === a.arrowData.endId || newId === a.arrowData.endId
                })[0].mappedService.serviceData
            })
        })
        newArrowsFinal.filter((a) => {
            return a.arrowData.endId === oldId
        }).forEach((a) => {
            a.arrowData.endId = newId
            mappedService.serviceData.arrowIn.push({
                arrowData: a.arrowData, serviceData: newServices.filter(({ oldId, newId }) => {
                    return oldId === a.arrowData.startId || newId === a.arrowData.startId
                })[0].mappedService.serviceData
            })
        })
    })
    return { newServices, newArrows: newArrowsFinal }
}